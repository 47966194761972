<template>
    <div class="container form-step">
      <Loader v-if="loading" />
        <div class="p-grid p-jc-center">
            <div class="p-col-8">
                <div class="card">
                    <Steps :model="items" :readonly="true" />
                </div>

                <router-view :key="reloadKey" v-slot="{Component}" :formData="formObject" @activeLoader="activeLoader" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete">
                    <keep-alive>
                        <component :is="Component" />
                    </keep-alive>
                </router-view>
            </div>       
        </div>
    </div>
</template>

<script>

import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import Steps from 'primevue/steps';
import Loader from "@/components/Loader.vue"

export default {
    components: {
        Steps,
        Loader
    },
    setup() { 
        onBeforeMount(() => {
          router.push(items.value[0].to); 
        })
        const loading = ref(false)
        const reloadKey = ref(0);
        const formObject = ref({});
        const router = useRouter();
 

        const items = ref([
          {
            label: 'Info Contratto',
            to: "/multisito/step-info"
          },
          {
            label: 'Rappresentante/Contatto',
            to: "/multisito/step-rappresentante"
          },
          {
            label: 'Carica Dati',
            to: "/multisito/step-upload"
          },
          {
            label: 'Carica Documenti',
            to: "/multisito/step-documenti"
          },
        ]);
        

        const nextPage = (event) => {
          for (let field in event.formData) {               
            formObject.value[field] = event.formData[field];
          }
          router.push(items.value[event.pageIndex + 1].to)
        };
        const prevPage = (event) => {
          loading.value = true
          router.push(items.value[event.pageIndex - 1].to);
          setTimeout(() => {
            loading.value = false
          }, 2000)
        };
        const complete = () => {
          // use for re-render component (clear data)
          reloadKey.value = Date.now();
          formObject.value = {}
          router.push(items.value[0].to);              
        };

        const activeLoader = (bool) => {
          loading.value = bool
        }

        return { items, formObject, nextPage, prevPage, complete, reloadKey, activeLoader, loading }
    }
}
</script>

<style>
 b {
     display: block;
 }

.form-step .p-card-body {
     padding: 2rem;
 }
 .form-step .p-card {
     box-shadow: none !important;
 }
 
  .form-step .p-field input ,  .form-step .p-dropdown{
     background-color: rgba(219, 219, 219, 0.45);
     border: 1px solid rgba(255, 255, 255, 0);
     height: 44px;
 }
 .form-step .p-field {
     text-align: left;
 }

 .form-step .p-error {
     font-size: 13px;
 }
 
</style>