<template>
  <div class="stepsdemo-content multisito-upload">
    <Toast :baseZIndex="10000" position="bottom-right" group="tc" />
    <Toast :baseZIndex="10000" position="bottom-right" group="failPrecheck" />
    <Toast :baseZIndex="10000" position="bottom-right" group="PrecheckKO" />
    <!-- <Toast :baseZIndex="10000" position="bottom-right" group="inactivePrecheck" /> -->
    <Toast :baseZIndex="10000" position="bottom-right" group="PrecheckOK" />
    <Card>
      <template v-slot:title>
        <p class="col-12 text-center">Carica Dati</p>
      </template>
      <template v-slot:subtitle>
        <div v-if="activeTabIndex == 0">
          <p>
            Inserisci i dati del cliente e fornitura cliccando sul tasto +
          </p>
        </div>
        <div v-if="activeTabIndex == 1">
          <p>
            Scarica il template tramite il link a destra e poi ricaricalo compilato
          </p>
          <p style="color:rgba(0, 94, 255, 0.684)">
            <strong>
              Funzionalità in fase di sviluppo. É necessario scaricare e utilizzare sempre l'ultima versione del template.
            </strong>
          </p>
          <div class="container-fluid m-3 border">
            <p style="color:rgba(0, 94, 255, 0.684)">
              <strong>
                Compatibile con OpenOffice.
              </strong>
            </p>
            <a class="p-button p-button-primary" target="_blank" href="https://www.openoffice.org/it/download/">Scarica qui la versione completa</a>
          </div>
        </div>
      </template>
      
      <template v-slot:content>
        <div>
          <Button label="Codici possibili dei distributori di Energia elettrica" :icon="`pi ${visibleCodDistributori ? 'pi-angle-down' : 'pi-angle-right'}`" iconPos="right" class="p-button-secondary m-3" @click="openCodDistributori"/>
          <div  v-if="visibleCodDistributori">
            <div class="flex flex-wrap mb-3">
              <Chip :label="code" v-for="code in cod_distributori" :key="code"/>
            </div>
            <p style="font-size: 20px;">Valido: IT<span style="color:limegreen">001</span>...</p>
            <p style="font-size: 20px;">Non valido: IT<span style="color:orangered">000</span>...</p>
          </div>
        </div>
        <div style="position: relative;">
          <Button class="btn-offerte btn-tutorial" icon="pi pi-question-circle" @click="openPosition" severity="success" v-tooltip="'Clicca per visualizzare il tutorial'"/>

          <Dialog v-model:visible="visible" header="Tutorial" :dismissableMask="true" modal :draggable="false" style="min-width: 300px;">
            <Carousel :value="slides" :numVisible="1">
              <template #item="slotProps">
                <div :class="['h-full',{'flex align-items-center': windowSize < 700}]">   

                  <div v-if="windowSize > 700" class="border-1 surface-border border-round text-center py-5 px-3">
                    <div class="mb-1">
                      <img :src="slotProps.data.name" :alt="slotProps.data.name" :class="['shadow-2 w-'+(windowSize < 1400 ? slotProps.data.size+3 : slotProps.data.size)]"/>
                    </div>
                  </div>
                  <p class="m-2 text-center"><strong>{{ slotProps.data.description }}</strong></p>
                </div>
              </template>
            </Carousel>
          </Dialog>
        </div>
        <div style="margin-top: 50px;">
          <div class="flex align-items-center" v-if="!is_pratica && typeClient == 2">
            <p class="m-0">Inserimento Avanzato</p>
            <InputSwitch v-model="checked_basic_view" @update:modelValue="changeViews($event)" class="m-3"/>
            <p class="m-0">Inserimento Lite</p>
          </div>
          <div class="flex align-items-start justify-content-evenly flex-wrap" v-if="views == 'basic'">
            <div class="col-12 card">
              <h5>Dati Cliente</h5>
              <div class="flex flex-wrap">
                <Fieldset legend="Anagrafica" class="col-12 lg:col-4 p-0 m-0">
                  <p class="m-0">{{ cliente.cognome }} {{ cliente.nome }}</p>
                </Fieldset>
                <Fieldset legend="Codice Fiscale" class="col-12 lg:col-4 p-0 m-0">
                  <p class="m-0">{{ cliente.codfis }}</p>
                </Fieldset>
                <Fieldset legend="Indirizzo di residenza" class="col-12 lg:col-4 p-0 m-0">
                  <p class="m-0">{{ cliente.toponimo_legale }} {{ cliente.indirizzo_legale }}, {{ cliente.civico_legale }} - {{ cliente.cap_legale }} {{ cliente.nome_comune }}</p>
                </Fieldset>
              </div>
            </div>
            <div class="col-12 lg:col-6 card">
              <Button class="p-button-luce p-button-custom flex justify-content-center" @click="addRowForType('ee')" v-if="!row_ee.exist">
                <p>Luce <i class="pi pi-plus"></i></p>
              </Button>
              <div v-else class="w-full" style="aspect-ratio: 1;position:relative">
                <Button icon="pi pi-trash" class="p-button-text p-button-danger" style="position:absolute;right: 2%" @click="removeRowForType('ee')"/>
                <div class="flex justify-content-center col-12">
                  <h4>Fornitura Luce <img class="icon-custom" alt="lightbulb icon" src="@/assets/img/lightbulb_120808.png"></h4>
                </div>
                <div class="col-12 flex flex-wrap">
                  <div class="col-12 lg:col-6">
                    <label>Pod</label>
                    <InputMask class="w-full" mask="IT999E9999999*" placeholder="Inserisci POD" v-model="row_ee.code" @update:modelValue="removeError('ee', 'POD_PDR')"></InputMask>
                    <div v-show="validationRowErrors.ee.POD_PDR" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                    <div v-show="validationRowErrors.ee.POD_PDR_INVALID" class="p-error mt-1" style="font-size:11px">Formato errato</div>
                    <div v-show="validationRowErrors.ee.POD_PDR_EXISTENT" class="p-error mt-1" style="font-size:11px">Già contrattualizzato</div>
                  </div>
                  <div class="col-12 lg:col-6">
                    <label>Iban</label>
                    <InputMask class="w-full text_toupper" mask="aa99a9999999999**9999999*99" placeholder="Inserisci IBAN" v-model="row_ee.iban"></InputMask>
                  </div>
                  <div class="col-12 lg:col-6">
                    <label>Uso</label>
                    <Dropdown v-model="row_ee.uso" :options="usiEnergiaDomestico" placeholder="Seleziona USO" optionValue="name" optionLabel="name" class="w-full" @change="removeError('ee', 'USO')"></Dropdown>
                    <div v-show="validationRowErrors.ee.USO" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6">
                    <label>Offerta</label>
                    <Dropdown v-model="row_ee.offerta" :options="offerteEnergia" placeholder="Seleziona OFFERTA" optionValue="id" optionLabel="name" :filter="true" class="w-full" @change="removeError('ee', 'OFFERTA')"></Dropdown>
                    <div v-show="validationRowErrors.ee.OFFERTA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <!-- <div class="col-12 flex align-items-center" v-if="!isManualContract && isResidential">
                    <label>L'indirizzo di fornitura coincide con l'indirizzo dei residenza</label>
                    <Checkbox v-model="row_ee.addressEqual" :binary="true" class="m-3"/>
                  </div> -->
                  <div class="col-12 lg:col-6" v-if="!row_ee.addressEqual">
                    <label>Toponimo</label>
                    <InputText v-model="row_ee.toponimo" @input="(val) => (row_ee.toponimo = row_ee.toponimo.toUpperCase())" class="w-full" @update:modelValue="removeError('ee', 'TOPONIMO_FORNITURA')"></InputText>
                    <div v-show="validationRowErrors.ee.TOPONIMO_FORNITURA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>

                  </div>
                  <div class="col-12 lg:col-6" v-if="!row_ee.addressEqual">
                    <label>Indirizzo</label>
                    <InputText v-model="row_ee.indirizzo" @input="(val) => (row_ee.indirizzo = row_ee.indirizzo.toUpperCase())" class="w-full" @update:modelValue="removeError('ee', 'INDIRIZZO_FORNITURA')"></InputText>
                    <div v-show="validationRowErrors.ee.INDIRIZZO_FORNITURA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6" v-if="!row_ee.addressEqual">
                    <label>Civico</label>
                    <InputText v-model="row_ee.civico" @input="(val) => (row_ee.civico = row_ee.civico.toUpperCase())" class="w-full" @update:modelValue="removeError('ee', 'CIVICO_FORNITURA')"></InputText>
                    <div v-show="validationRowErrors.ee.CIVICO_FORNITURA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6" v-if="!row_ee.addressEqual">
                    <label>Cap</label>
                    <InputMask v-model="row_ee.cap" mask="99999" class="w-full" @update:modelValue="removeError('ee', 'CAP_FORNITURA')"></InputMask>
                    <div v-show="validationRowErrors.ee.CAP_FORNITURA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6" v-if="!row_ee.addressEqual">
                    <label>Provincia</label>
                    <Dropdown v-model="row_ee.provincia" class="w-full" :options="districts" placeholder="Seleziona PROVINCIA" :filter="true" optionLabel="name" optionValue="code" @change="onSelectedDistrictForType('ee', $event, true)" />
                    <div v-show="validationRowErrors.ee.SIGLA_PROVINCIA_FORNITURA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>

                  </div>
                  <div class="col-12 lg:col-6" v-if="!row_ee.addressEqual">
                    <label>Comune</label>
                    <Dropdown v-model="row_ee.comune" :options="row_ee.citiesGrouped" placeholder="Seleziona COMUNE" :filter="true" optionLabel="name" optionValue="name" class="w-full"  @change="removeError('ee', 'CITTA_FORNITURA')"/>
                    <div v-show="validationRowErrors.ee.CITTA_FORNITURA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <!-- <div class="col-12 lg:col-6" v-if="pratica == 'v'">
                    <label>CODICE FISCALE PRECEDENTE</label>
                    <InputText v-model="row_ee.cf_attivazione" autofocus @input="(val) => (row_ee.cf_attivazione = row_ee.cf_attivazione.toUpperCase())" max-length="16"/>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="col-12 lg:col-6 card flex justify-content-center align-items-center">
              <Button class="p-button-gas p-button-custom flex justify-content-center" @click="addRowForType('gas')" v-if="!row_gas.exist">
                <p>Gas <i class="pi pi-plus"></i></p>
              </Button>
              <div v-else class="w-full" style="aspect-ratio: 1;position:relative">
                <Button icon="pi pi-trash" class="p-button-text p-button-danger" style="position:absolute;right: 2%" @click="removeRowForType('gas')"/>
                <div class="flex justify-content-center col-12">
                  <h4>Fornitura Gas <img class="icon-custom" alt="fire icon" src="@/assets/img/gas_industry-48_44627.png"></h4>
                </div>
                <div class="col-12 flex flex-wrap">
                  <div class="col-12 lg:col-6">
                    <label>Pdr</label>
                    <InputMask class="w-full" mask="99999999999999" placeholder="Inserisci PDR" v-model="row_gas.code" @update:modelValue="removeError('gas', 'POD_PDR')"></InputMask>
                    <div v-show="validationRowErrors.gas.POD_PDR" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                    <div v-show="validationRowErrors.gas.POD_PDR_INVALID" class="p-error mt-1" style="font-size:11px">Formato errato</div>
                    <div v-show="validationRowErrors.gas.POD_PDR_EXISTENT" class="p-error mt-1" style="font-size:11px">Già contrattualizzato</div>
                  </div>
                  <div class="col-12 lg:col-6">
                    <label>Iban</label>
                    <InputMask class="w-full text_toupper" mask="aa99a9999999999**9999999*99" placeholder="Inserisci IBAN" v-model="row_gas.iban"></InputMask>
                  </div>
                  <div class="col-12 lg:col-6">
                    <label>Uso</label>
                    <Dropdown v-model="row_gas.uso" :options="usiGasDomestico" placeholder="Seleziona USO" optionValue="name" optionLabel="name" class="w-full" @change="removeError('gas', 'USO')"></Dropdown>
                    <div v-show="validationRowErrors.gas.USO" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6">
                    <label>Offerta</label>
                    <Dropdown v-model="row_gas.offerta" :options="offerteGas" placeholder="Seleziona OFFERTA" optionValue="id" optionLabel="name" :filter="true" class="w-full" @change="removeError('gas', 'OFFERTA')"></Dropdown>
                    <div v-show="validationRowErrors.gas.OFFERTA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 flex align-items-center">
                    <label>L'indirizzo di fornitura coincide con l'indirizzo dei residenza</label>
                    <Checkbox v-model="row_gas.addressEqual" :binary="true" class="m-3"/>
                  </div>
                  <div class="col-12 lg:col-6" v-if="!row_gas.addressEqual">
                    <label>Toponimo</label>
                    <InputText v-model="row_gas.toponimo" @input="(val) => (row_gas.toponimo = row_gas.toponimo.toUpperCase())" class="w-full" @update:modelValue="removeError('gas', 'TOPONIMO_FORNITURA')"></InputText>
                    <div v-show="validationRowErrors.gas.TOPONIMO_FORNITURA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6" v-if="!row_gas.addressEqual">
                    <label>Indirizzo</label>
                    <InputText v-model="row_gas.indirizzo" @input="(val) => (row_gas.indirizzo = row_gas.indirizzo.toUpperCase())" class="w-full" @update:modelValue="removeError('gas', 'INDIRIZZO_FORNITURA')"></InputText>
                    <div v-show="validationRowErrors.gas.INDIRIZZO_FORNITURA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6" v-if="!row_gas.addressEqual">
                    <label>Civico</label>
                    <InputText v-model="row_gas.civico" @input="(val) => (row_gas.civico = row_gas.civico.toUpperCase())" class="w-full" @update:modelValue="removeError('gas', 'CIVICO_FORNITURA')"></InputText>
                    <div v-show="validationRowErrors.gas.CIVICO_FORNITURA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6" v-if="!row_gas.addressEqual">
                    <label>Cap</label>
                    <InputMask v-model="row_gas.cap" mask="99999" class="w-full" @change="removeError('gas', 'CAP_FORNITURA')"></InputMask>
                    <div v-show="validationRowErrors.gas.CAP_FORNITURA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6" v-if="!row_gas.addressEqual">
                    <label>Provincia</label>
                    <Dropdown v-model="row_gas.provincia" class="w-full" :options="districts" placeholder="Seleziona PROVINCIA" :filter="true" optionLabel="name" optionValue="code" @change="onSelectedDistrictForType('gas', $event, true)"/>
                    <div v-show="validationRowErrors.gas.SIGLA_PROVINCIA_FORNITURA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6" v-if="!row_gas.addressEqual">
                    <label>Comune</label>
                    <Dropdown v-model="row_gas.comune" :options="row_gas.citiesGrouped" placeholder="Seleziona COMUNE" :filter="true" optionLabel="name" optionValue="name" class="w-full"  @change="removeError('gas', 'CITTA_FORNITURA')"/>
                    <div v-show="validationRowErrors.gas.CITTA_FORNITURA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6">
                    <label>Matricola</label>
                    <InputText v-model="row_gas.matricola" @input="(val) => (row_gas.matricola = row_gas.matricola.toUpperCase())" class="w-full" @update:modelValue="removeError('gas', 'MATRICOLA')"></InputText>
                    <div v-show="validationRowErrors.gas.MATRICOLA" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6">
                    <label>Classe misuratore</label>
                    <Dropdown v-model="row_gas.classe" :options="classeOption" placeholder="Seleziona CLASSE MISURATORE" class="w-full" @change="removeError('gas', 'CLASSE_MISURATORE')">
                      <template #value="slotProps">
                        <div v-if="slotProps.value">
                          <div>{{slotProps.value.classe}}</div>
                        </div>
                      </template>
                      <template #option="slotProps">
                        <span>{{slotProps.option.classe}}</span>
                      </template>
                    </Dropdown>
                    <div v-show="validationRowErrors.gas.CLASSE_MISURATORE" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6">
                    <label>Destinazione d'uso</label>
                    <Dropdown v-model="row_gas.destinazione" :options="destinazioneOption" placeholder="Seleziona DESTINAZIONE D'USO" class="w-full" @change="removeError('gas', 'DESTINAZIONE_USO')">
                      <template #value="slotProps">
                        <div v-if="slotProps.value">
                          <div>{{slotProps.value.name}}</div>
                        </div>
                      </template>
                      <template #option="slotProps">
                        <span>{{slotProps.option.name}}</span>
                      </template>
                    </Dropdown>
                    <div v-show="validationRowErrors.gas.DESTINAZIONE_USO" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                  <div class="col-12 lg:col-6">
                    <label>Consumo annuo</label>
                    <InputNumber v-model="row_gas.consumo" inputId="integeronly" class="w-full" @update:modelValue="removeError('gas', 'CONSUMO_ANNUO')"/>
                    <div v-show="validationRowErrors.gas.CONSUMO_ANNUO" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TabView v-model:activeIndex="activeTabIndex" class="stepUpload-multisito" v-if="views == 'advanced'">
            <TabPanel header="Upload Manuale">
              <div class="flex justify-content-end mx-2" v-if="manualRows.length > 0">
                <small @click="deleteAllRows" class="cursor-pointer"><p class="p-error">Elimina tutto ({{manualRows.length}})</p></small>
              </div>                
                <DataTable :value="manualRows" editMode="cell" @cell-edit-init="onCellEditInit" @cell-edit-complete="onCellEditComplete" :responsiveLayout="responsiveLayout" :breakpoint="breakpoint">
                  <template #empty>
                    <div>Nessun dato</div>
                  </template>
                  <Column style="min-width: 10rem" v-if="prechecked">
                    <template #body="{index}">
                      <div v-if="manualRows[index].PRECHECKOK" class="p-success mt-1">
                        Precheck OK
                      </div>
                      <div v-else class="p-error mt-1">
                        Ricontrolla i dati inseriti o procedi con <strong>{{ manualRows[index].COD_ESITO != '' && esiti_subentro.includes(manualRows[index].COD_ESITO) ? 'Subentro' : 'Voltura'}}</strong>
                      </div>
                    </template>
                  </Column>
                  <Column v-for="header of headings" :key="header" :field="header" :header="header">
                    <template #body="{ data, index }">
                      <div class="flex flex-wrap">
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <div v-if="header == 'RAGIONE_SOCIALE' || header == 'CODICE_FISCALE_PIVA' || header == 'CODICE_SDI' || header == 'POD_PDR' || header == 'IBAN'">{{data[header] == '' ? 'Inserisci...' : data[header]}}</div>
  
                          <div v-else>{{(data[header] == '' && isVisibleAddress(index)) ? 'Inserisci...' : data[header]}}</div>
                        </div>
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <div v-show="validationErrors[`${header}_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                          <div v-show="validationErrors[`${header}_existent_${index}`]" class="p-error mt-1" style="font-size:11px">Duplicato</div>
                          <div v-show="validationErrors[`${header}_exist_${index}`]" class="p-error mt-1" style="font-size:11px">Già contrattualizzato</div>
                          <div v-show="validationErrors[`${header}_INVALID_${index}`]" class="p-error mt-1" style="font-size:11px">Formato errato</div>
                          <div v-show="validationErrors[`${header}_precheck_${index}`]" class="p-error mt-1" style="font-size:11px">{{manualRows[index].DETTAGLIO_ESITO}}</div>
                          <div v-show="validationErrors[`${header}_NO_${index}`]" class="p-error mt-1" style="font-size:11px">Codice sdi non valido</div>
                        </div>
                      </div>
                    </template>
                    <template #editor="{ data, index }">
                      <InputText v-if="header == 'RAGIONE_SOCIALE'" v-model="data[header]" :id="`${header}_${index}`" autofocus @input="(val) => (data[header] = data[header].toUpperCase())" :disabled="(typeClient != 3 || manualRows[index].PRECHECKOK) && data[header] != ''"/>
  
                      <InputMask v-if="header == 'CODICE_FISCALE_PIVA'" v-model="data[header]" :id="`${header}_${index}`" autofocus :mask="maskCF" :disabled="(typeClient != 3 || manualRows[index].PRECHECKOK) && data[header] != ''" class="text_toupper"/>
  
                      <Dropdown v-if="header == 'CODICE_SDI'"
                      v-model="data[header]"
                      :options="sdi" 
                      placeholder="Seleziona" 
                      :filter="true"
                      :showClear="true"
                      optionLabel="sdi" optionValue="sdi"                    
                      :disabled="manualRows[index].PRECHECKOK || typeClient == 2"
                      @change="onSelectedSdi(index, $event)"
                      />
  
                      <InputMask class="text_toupper" v-if="header == 'IBAN'" v-model="data[header]" :id="`${header}_${index}`" autofocus :disabled="manualRows[index].PRECHECKOK" mask="aa99a9999999999**9999999*99" @blur="onInputCF_PIVA($event, index, 'IBAN')"/>
  
                      <InputMask class="text_toupper" v-if="header == 'POD_PDR'" v-model="data[header]" :id="`${header}_${index}`" autofocus :disabled="manualRows[index].PRECHECKOK" mask="**999*9999999*"/>
  
                      <InputText v-if="(header != 'RAGIONE_SOCIALE' && header != 'CODICE_FISCALE_PIVA' && header != 'CODICE_SDI' && header != 'POD_PDR' && header != 'IBAN') && manualRows[index].POD_PDR != '' && isVisibleAddress(index)" v-model="data[header]" :id="`${header}_${index}`" autofocus @input="(val) => (data[header] = data[header].toUpperCase())" :disabled="manualRows[index].PRECHECKOK"/>
                    </template>
                  </Column>
                  <Column field="SIGLA_PROVINCIA_FORNITURA" header="SIGLA_PROVINCIA_FORNITURA" style="min-width: 8rem">
                    <template #body="{data, index}">
                      <div class="flex flex-wrap">
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <Dropdown 
                          v-if="manualRows[index].POD_PDR != '' && isVisibleAddress(index)"
                          v-model="data['SIGLA_PROVINCIA_FORNITURA']"
                          :options="districts" 
                          placeholder="Seleziona"
                          :filter="true"
                          optionLabel="name" optionValue="code"                    
                          @change="onSelectedDistrict(index, $event, true)"
                          :disabled="manualRows[index].PRECHECKOK"
                          />
                        </div>
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <div v-show="validationErrors[`SIGLA_PROVINCIA_FORNITURA_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                          <div v-show="validationErrors[`SIGLA_PROVINCIA_FORNITURA_IN_${index}`]" class="p-error mt-1" style="font-size:11px">Sigla della provincia inserita non riconosciuta</div>
                        </div>
                      </div>
                    </template>
                  </Column>
                  <Column field="CITTA_FORNITURA" header="CITTA_FORNITURA" style="min-width: 8rem">
                    <template #body="{data, index}">
                      <div class="flex flex-wrap">
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <Dropdown 
                          v-if="manualRows[index].POD_PDR != '' && isVisibleAddress(index)"
                          v-model="data[`CITTA_FORNITURA`]" :options="citiesGrouped[index]" 
                          placeholder="Seleziona" 
                          :filter="true"
                          optionLabel="name" 
                          optionValue="name"
                          @change="onSelectedCity(index)"
                          :disabled="manualRows[index].PRECHECKOK"
                          />
                          
                        </div>
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <div v-show="validationErrors[`CITTA_FORNITURA_IN_${index}`]" class="p-error mt-1" style="font-size:11px">Città sconosciuta per la provincia selezionata</div>
                          <div v-show="validationErrors[`CITTA_FORNITURA_NO_${index}`]" class="p-error mt-1" style="font-size:11px">Inserire una provincia valida per selezionare la città</div>
                          <div v-show="validationErrors[`CITTA_FORNITURA_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                          
                        </div>
                      </div>
                    </template>
                  </Column>
                  <Column field="USO" header="USO" style="min-width: 8rem">
                    <template #body="{data, index}">
                      <div class="flex flex-wrap">
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <Dropdown v-model="data['USO']" 
                          :options="usiOption[index]" 
                          placeholder="Seleziona Uso" 
                          @change="onSelectedUso(index)"
                          :disabled="manualRows[index].PRECHECKOK"
                          optionValue="name"
                          optionLabel="name"
                          >
                          <template #empty>
                            Inserisci un pod o un pdr
                          </template>
                        </Dropdown>
                      </div>
                      <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                        <div v-show="validationErrors[`USO_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                        <div v-show="validationErrors[`USO_NO_${index}`]" class="p-error mt-1" style="font-size:11px">Campo non valido</div>
                      </div>
                    </div>
                  </template>
                  </Column>
                  <Column field="OFFERTA" header="OFFERTA" style="min-width: 8rem">
                    <template #body="{data, index}">
                      <div class="flex flex-wrap">
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <Dropdown v-model="data['OFFERTA']" :options="offerteOption[index]" placeholder="Seleziona Offerta" @change="onSelectedOfferta(index)"
                          :disabled="manualRows[index].PRECHECKOK"
                          optionValue="id"
                          optionLabel="name"
                          :filter="true">
                          <template #empty>
                            Inserisci un pod o un pdr
                          </template>
                        </Dropdown>
                      </div>
                      <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                        <div v-show="validationErrors[`OFFERTA_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                        <div v-show="validationErrors[`OFFERTA_NO_${index}`]" class="p-error mt-1" style="font-size:11px">Seleziona un'offerta valida per la fornitura inserita</div>
                      </div>
                    </div>
                  </template>
                  </Column>
                  <Column field="CF_ATTIVAZIONE" header="CF PRECEDENTE INTESTATARIO" v-if="pratica == 'v'">
                    <template #body="{data, index}">
                      <!-- <InputMask class="text_toupper" :mask="`${maskCF}`" v-model="data['CF_ATTIVAZIONE']" autofocus :disabled="manualRows[index].PRECHECKOK" @blur="onInputCF_PIVA($event, index, 'CF_ATTIVAZIONE')"/> -->
                      
                      <!-- @input="(val) => (data[header] = data[header].toUpperCase())" -->
                      <div class="flex flex-wrap">
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <InputText v-model="data['CF_ATTIVAZIONE']" :id="`${header}_${index}`" autofocus @input="onInputCF_PIVA($event, index, 'CF_ATTIVAZIONE')" :disabled="manualRows[index].PRECHECKOK" max-length="16"/>
                        </div>
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <div v-show="validationErrors[`CF_ATTIVAZIONE_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                          <div v-show="validationErrors[`CF_ATTIVAZIONE_INVALID_${index}`]" class="p-error mt-1" style="font-size:11px">Formato errato</div>
                          <div v-show="validationErrors[`CF_PIVA_ATTIVAZIONE_NO_${index}`]" class="p-error mt-1" style="font-size:11px">Almeno un campo tra CF e PIVA ATTIVAZIONE è necessario</div>
                          <div v-show="validationErrors[`CF_ATTIVAZIONE_equal_${index}`]" class="p-error mt-1" style="font-size:11px">CF ATTIVAZIONE non può essere uguale<br>al codice fiscale del cliente da attivare</div>
  
                        </div>
                      </div>
                    </template>
                  </Column>
                  <Column field="PIVA_ATTIVAZIONE" header="PIVA PRECEDENTE INTESTATARIO" v-if="pratica == 'v'">
                    <template #body="{data, index}">
                      <div class="flex flex-wrap">
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <InputMask mask="99999999999" v-model="data['PIVA_ATTIVAZIONE']" autofocus :disabled="manualRows[index].PRECHECKOK" @update:modelValue="onInputCF_PIVA($event, index, 'PIVA_ATTIVAZIONE')"/>
                        </div>
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <div v-show="validationErrors[`PIVA_ATTIVAZIONE_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                          <div v-show="validationErrors[`PIVA_ATTIVAZIONE_INVALID_${index}`]" class="p-error mt-1" style="font-size:11px">Formato errato</div>
                          <div v-show="validationErrors[`PIVA_ATTIVAZIONE_equal_${index}`]" class="p-error mt-1" style="font-size:11px">PIVA ATTIVAZIONE non può essere uguale<br>alla partita iva del cliente da attivare</div>
                        </div>
                      </div>
                    </template>
                  </Column>
                  <Column header="DATA_DECORRENZA" v-if="is_pratica">
                    <template #body="{data, index}">
                      <div class="flex flex-wrap">
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <Calendar v-model="data['DATA_DECORRENZA']" :numberOfMonths="2" :minDate="minDate" :manualInput="false" dateFormat="dd/mm/yy" showIcon @update:modelValue="onChangeDate($event, index)"/>
                        </div>
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <div v-show="validationErrors[`DATA_DECORRENZA_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                        </div>
                      </div>
                    </template>
                  </Column>
                  <Column field="MATRICOLA" header="MATRICOLA">
                    <template #body="{data, index}">
                      <div v-if="manualRows[index].TIPO == 'pdr' && manualRows[index].POD_PDR != ''" class="flex flex-wrap">
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <inputText v-model="data['MATRICOLA']" @input="onInput($event, index, 'MATRICOLA')" :binary="true" :disabled="manualRows[index].PRECHECKOK"/>  
                        </div>
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <div v-show="validationErrors[`MATRICOLA_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                        </div>
                      </div>
                    </template>
                  </Column>
                  <Column field="CLASSE_MISURATORE" header="CLASSE_MISURATORE">
                    <template #body="{data, index}">
                      <div v-if="manualRows[index].TIPO == 'pdr' && manualRows[index].POD_PDR != ''" class="flex flex-wrap">
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <Dropdown v-model="data['CLASSE_MISURATORE']" :options="classeOption" placeholder="Seleziona" :disabled="manualRows[index].PRECHECKOK" @change="onSelectedClasse(index)">
                            <template #value="slotProps">
                            <div v-if="slotProps.value">
                              <div>{{slotProps.value.classe}}</div>
                            </div>
                          </template>
                          <template #option="slotProps">
                            <span>{{slotProps.option.classe}}</span>
                          </template>
                        </Dropdown>
                        </div>
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <div v-show="validationErrors[`CLASSE_MISURATORE_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                          <div v-show="validationErrors[`CLASSE_MISURATORE_NO_${index}`]" class="p-error mt-1" style="font-size:11px">Campo errato</div>
                        </div>
                      </div>
                    </template>
                  </Column>
                  <Column field="DESTINAZIONE_USO" header="DESTINAZIONE_USO">
                    <template #body="{data, index}">
                      <div v-if="manualRows[index].TIPO == 'pdr' && manualRows[index].POD_PDR != ''" class="flex flex-wrap">
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <Dropdown v-model="data['DESTINAZIONE_USO']" :options="destinazioneOption" placeholder="Seleziona" :disabled="manualRows[index].PRECHECKOK" @change="onSelectedDestinazione(index)">
                            <template #value="slotProps">
                              <div v-if="slotProps.value">
                                <div>{{slotProps.value.name}}</div>
                              </div>
                            </template>
                            <template #option="slotProps">
                              <span>{{slotProps.option.name}}</span>
                            </template>
                          </Dropdown>
                        </div>
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <div v-show="validationErrors[`DESTINAZIONE_USO_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                          <div v-show="validationErrors[`DESTINAZIONE_USO_NO_${index}`]" class="p-error mt-1" style="font-size:11px">Campo errato</div>
                        </div>
                      </div>
                    </template>
                  </Column>
                  <Column field="CONSUMO_ANNUO" header="CONSUMO_ANNUO">
                    <template #body="{data, index}">
                      <div v-if="manualRows[index].TIPO == 'pdr' && !manualRows[index].SUBENTRO && manualRows[index].POD_PDR != ''" class="flex flex-wrap">
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <InputNumber v-model="data['CONSUMO_ANNUO']" inputId="integeronly" :disabled="manualRows[index].PRECHECKOK" @update:modelValue="onInput($event, index, 'CONSUMO_ANNUO')"/>
                        </div>
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <div v-show="validationErrors[`CONSUMO_ANNUO_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                        </div>
                      </div>
                    </template>
                  </Column>
                  <Column field="REMI" header="REMI" v-if="pratica == 's'">
                    <template #body="{data, index}">
                      <div v-if="manualRows[index].TIPO == 'pdr' && manualRows[index].POD_PDR != ''" class="flex flex-wrap">
                        <Dropdown v-model="data['REMI']" 
                        :options="remiOption[index]" 
                        placeholder="Seleziona Remi" 
                        @change="onSelectedRemi(index)"
                        :disabled="manualRows[index].PRECHECKOK"
                        optionValue="remi" optionLabel="remi"
                        />
                        
                        <div :class="`col-12 p-0 flex justify-content-${windowSize <= 800 ? 'end' : 'start'}`">
                          <div v-show="validationErrors[`REMI_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                          <div v-show="validationErrors[`REMI_ERROR_${index}`]" class="p-error mt-1" style="font-size:11px">Codice Remi non valido</div>
                        </div>
                      </div>
                    </template>

                  </Column>

                  <!-- <Column v-if="submitted" :exportable="false">
                    <template #body="{ data, index }">
                      <div class="flex flex-nowrap">
                        <div v-if="esiti_subentro.includes(manualRows[index].COD_ESITO) && !manualRows[index].PRECHECKOK">
                          <div class="col-4 col-md-4">
                            <label class="ml-2"><strong>Subentro?</strong></label>
                            <div class="col-12 md:col-6 m-4 flex align-items-center">
                              <Checkbox v-model="data['SUBENTRO']" :binary="true" :disabled="manualRows[index].PRECHECKOK"/>
                            </div>
                          </div>
                        </div>
                        <div v-if="esiti_voltura.includes(manualRows[index].COD_ESITO) && !manualRows[index].PRECHECKOK">
                          <div class="col-4 col-md-4">
                            <label class="ml-2"><strong>Voltura?</strong></label>
                            <div class="col-12 md:col-6 m-4 flex align-items-center">
                              <Checkbox v-model="data['VOLTURA']" :binary="true" :disabled="manualRows[index].PRECHECKOK"/>
                            </div>
                          </div>
                        </div>
                        <div v-if="manualRows[index].VOLTURA && manualRows[index].PRECHECKOK">
                          <div class="col-4 col-md-4">
                            <p>IN VOLTURA</p>
                          </div>
                        </div>
                        <div v-if="manualRows[index].SUBENTRO && manualRows[index].PRECHECKOK">
                          <div class="col-4 col-md-4">
                            <p>IN SUBENTRO</p>
                          </div>
                        </div>
                        <div v-if="manualRows[index].VOLTURA && !manualRows[index].PRECHECKOK">
                          <label class="col-12 flex align-items-center" style="margin-left: 20px !important"><strong>CODICE FISCALE o PIVA PRECEDENTE</strong></label>
                          <div class="col-12 md:col-6 m-4 flex align-items-center">
                            <inputText v-model="data['CODICE_FISCALE_PIVA_PRECEDENTE']" @input="(val) => (data['CODICE_FISCALE_PIVA_PRECEDENTE'] = data['CODICE_FISCALE_PIVA_PRECEDENTE'].toUpperCase())" :binary="true" :disabled="manualRows[index].PRECHECKOK"/>  
                          </div>
                          <div v-show="validationErrors[`CODICE_FISCALE_PIVA_PRECEDENTE_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                          <div v-show="validationErrors[`CODICE_FISCALE_PIVA_PRECEDENTE_INVALID_${index}`]" class="p-error mt-1" style="font-size:11px">Formato errato</div>
                          <div v-show="validationErrors[`CODICE_FISCALE_PIVA_PRECEDENTE_precheck_${index}`]" class="p-error mt-1" style="font-size:11px">{{manualRows[index].DETTAGLIO_ESITO}}</div>
                        </div>
                        <div v-if="manualRows[index].SUBENTRO && !manualRows[index].PRECHECKOK && manualRows[index].TIPO == 'pdr'">
                          <label class="col-12 flex align-items-center" style="margin-left: 20px !important"><strong>CODICE REMI</strong></label>
                            <Dropdown v-model="data['REMI']" 
                            :options="remiOption[index]" 
                            optionLabel="remi"
                            optionValue="remi"
                            placeholder="Seleziona Remi" 
                            class="w-full"
                            :editable="true"
                            :disabled="manualRows[index].PRECHECKOK"
                            :filter="true"
                            >
                            <template #empty>
                              <div class="text-center">Inserisci il codice remi manualmente</div>
                            </template>
                            </Dropdown>
                          <div v-show="validationErrors[`REMI_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                          <div v-show="validationErrors[`REMI_INVALID_${index}`]" class="p-error mt-1" style="font-size:11px">Formato errato</div>
                          <div v-show="validationErrors[`REMI_NO_${index}`]" class="p-error mt-1" style="font-size:11px">Remi inesistente</div>
                        </div>
                      </div>
                    </template>
                  </Column> -->
                  <Column :exportable="false" style="min-width: 3rem">
                    <template #body="{ data, index }">
                      <i class="pi pi-trash p-error" @click="deleteRow(data, index)" />
                    </template>
                  </Column>
                  
                </DataTable>
                
                <div class="mt-2">
                  <div class="flex justify-content-between">
                    <div>
                      <Button class="btn-offerte" type="button" icon="pi pi-question-circle" v-tooltip="`Legenda`" @click="toggle"/>
                      <OverlayPanel ref="op" id="legenda">
                        <div class="card font-size w-10rem">
                          <div v-for="field in fields" :key="field">
                            <span>{{ field.name }}</span> -
                            <span v-if="field.description.includes('Obbligatorio')">
                              <strong style="color: red">Obbligatorio</strong>
                            </span>
                            {{ field.description.replace('Obbligatorio', '')}}
                          </div>
                        </div>
                      </OverlayPanel>
                    </div>
                    
                    <div>
                      <Button v-tooltip="`Aggiungi riga`" @click="addRow" icon="pi pi-plus" class="p-button-rounded p-button-success" />
                    </div>
                  </div>
                </div>
  
                <!-- <div class="flex justify-content-end" style="margin-top:10px">
                  <Button v-tooltip="`Aggiungi riga`" @click="addRow" icon="pi pi-plus" class="p-button-rounded p-button-success" />
                </div> -->
                
            </TabPanel>
            <TabPanel header="Upload File">
              <div class="flex justify-content-end">
                <Button label="Scarica template da compilare" @click="downloadTemplate" class="p-button p-button-secondary" />
              </div>
              
              <div class="formgrid grid form-file">
                <div class="field col-12">
                  <div class="text-center allowed-format"> Formati consentiti: {{Object.keys(allowedType).join(', ')}} </div>
                  <div class="button-wrapper">
                    <span :class="['label', {'active': fileToUpload.length > 0}]">
                      {{fileToUpload.length > 0 ? fileToUpload[0].name : 'Carica il template compilato qui' }}
                    </span>
                    <input id="upload" type="file" ref="file" :key="keyInputReset" @change="handleFileUpload( $event )" :accept="`${Object.values(allowedType).join(', ')}`" />
                  </div>
                  <div @click="removeFile" v-if="fileToUpload.length > 0" class="remove-file">rimuovi file</div>
                  <div v-show="validationErrors.type && submitted" class="p-error">Formato non consentito</div>
                </div>
              </div>
              
            </TabPanel>
              
          </TabView> 
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid justify-content-between">
          <Button label="Indietro" @click="prevPage()" icon="pi pi-angle-left" iconPos="left" class="p-btn-footer"/>
          <i></i>
          <Button label="Avanti" @click="nextPage()" v-if="canGoToNextPage" icon="pi pi-angle-right" iconPos="right" class="p-btn-footer" />  
          <Button label="Importa" @click="importRowsFileToTable()" v-if="activeTabIndex == 1 && fileToUpload.length > 0" icon="pi pi-angle-right" iconPos="right" class="p-btn-footer"/>  
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
  import {ref, getCurrentInstance, onMounted, nextTick, onUpdated, onUnmounted, computed} from 'vue';
  import Service from '@/services/ask-pratiche/pratiche-wizard'
  import { useToast } from "primevue/usetoast";
  var XLSX = require("xlsx");

  export default {
    components: {
    },
    props: {
      formData: Object
    },
    setup (props, {emit}) {
      const toast = useToast();
      const self = getCurrentInstance();
      self.$eventBus = self.appContext.config.globalProperties.$eventBus
      const service = new Service(self.$eventBus)
      const headings = [
        'RAGIONE_SOCIALE',
        'CODICE_FISCALE_PIVA',
        'CODICE_SDI',
        'IBAN',
        'POD_PDR',
        'TOPONIMO_FORNITURA',
        'INDIRIZZO_FORNITURA',
        'CIVICO_FORNITURA',
        'CAP_FORNITURA',
      ]
      const fields = ref([])
      const validation_pod = ref([
        'RAGIONE_SOCIALE',
        'CODICE_FISCALE_PIVA',
        'POD_PDR',
        'TOPONIMO_FORNITURA',
        'INDIRIZZO_FORNITURA',
        'CIVICO_FORNITURA',
        'CAP_FORNITURA',       
        'SIGLA_PROVINCIA_FORNITURA',
        'CITTA_FORNITURA',
        'USO',
        'OFFERTA',
      ])
      const validation_pdr = ref([
        'RAGIONE_SOCIALE',
        'CODICE_FISCALE_PIVA',
        'POD_PDR',
        'TOPONIMO_FORNITURA',
        'INDIRIZZO_FORNITURA',
        'CIVICO_FORNITURA',
        'CAP_FORNITURA',       
        'SIGLA_PROVINCIA_FORNITURA',
        'CITTA_FORNITURA',
        'USO',
        'OFFERTA',
        'MATRICOLA',
        'CLASSE_MISURATORE',
        'DESTINAZIONE_USO',
        'CONSUMO_ANNUO'
      ])
      const manualRows = ref([])
      const editingRows = ref([]);
      const activeTabIndex = ref(0)
      let stepIndex = 2
      const fileToUpload = ref([])
      const offerte = ref([])
      const offerteOption = ref([])
      const offerteEnergia = ref([])
      const offerteGas = ref([])
      const allowedType = {
        'xlsx' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
      const type_matrice = ref('');
      const matrice_id = ref('');
      const keyInputReset = ref(0)
      const validationErrors = ref({})
      const validationRowErrors = ref({
        ee:{
          RAGIONE_SOCIALE: false,
          CODICE_FISCALE_PIVA: false,
          POD_PDR: false,
          POD_PDR_EXISTENT: false,
          POD_PDR_INVALID: false,
          TOPONIMO_FORNITURA: false,
          INDIRIZZO_FORNITURA: false,
          CIVICO_FORNITURA: false,
          CAP_FORNITURA: false,       
          SIGLA_PROVINCIA_FORNITURA: false,
          CITTA_FORNITURA: false,
          USO: false,
          OFFERTA: false
        },
        gas:{
          RAGIONE_SOCIALE: false,
          CODICE_FISCALE_PIVA: false,
          POD_PDR: false,
          POD_PDR_INVALID: false,
          POD_PDR_EXISTENT: false,
          TOPONIMO_FORNITURA: false,
          INDIRIZZO_FORNITURA: false,
          CIVICO_FORNITURA: false,
          CAP_FORNITURA: false,       
          SIGLA_PROVINCIA_FORNITURA: false,
          CITTA_FORNITURA: false,
          USO: false,
          OFFERTA: false,
          MATRICOLA: false,
          CLASSE_MISURATORE: false,
          DESTINAZIONE_USO: false,
          CONSUMO_ANNUO:false
        }
      })
      const submitted = ref(false)
      const showModal = ref(false)
      const cities = ref([])
      const sdi = ref([])
      const citiesGrouped = ref([])
      const districts = ref([])
      const usiOption = ref([])
      const remiOption = ref([])
      const totalRemi = ref([])
      const destinazioneOption = ref([])
      const classeOption = ref([])
      const usiEnergia = ref([
        {id:1, name:'Illuminazione Pubblica'},
        {id:2, name:'Altri Usi'},
        {id:3, name:'Domestico residente'},
        {id:4, name:'Domestico non residente'},
      ]);
      const usiEnergiaDomestico = ref();
      const usiGas = ref([
        {id:2, name:'Altri Usi'},
        {id:4, name:'Domestico'}
      ])

      const possible_usiEnergia_for_typeClient = ref([
        {typeClient: 1, id_usi: [2]},
        {typeClient: 2, id_usi: [2,3,4]},
        {typeClient: 3, id_usi: [2]},
        {typeClient: 4, id_usi: [2]},
        {typeClient: 5, id_usi: [1,2]},
        {typeClient: 6, id_usi: [1,2]}
      ])

      const possible_usiGas_for_typeClient = ref([
        {typeClient: 1, id_usi: [2]},
        {typeClient: 2, id_usi: [4]},
        {typeClient: 3, id_usi: [2]},
        {typeClient: 4, id_usi: [2]},
        {typeClient: 5, id_usi: [2]},
        {typeClient: 6, id_usi: [2]}
      ])

      const usiGasDomestico = ref();

      const array_esito = ref([])
      const esiti_voltura = ref([
        '002','003','006','910', '103', '104', '107', '201'
      ])
      const esiti_subentro = ref([
        '004', '102'
      ])
      const esiti_noprecheck = ref([
        '005', '105', '106', '202', '203'
      ])
      const prechecked = ref(false)
      const op = ref();
      const visible = ref(false)
      const slides = ref([])
      const typeClient = ref()
      const windowSize = ref(window.innerWidth)
      const breakpoint = ref('800px') 
      // const has_cf_attivazione = ref(false)  
      
      const maskCF = ref()
      // const has_piva_attivazione = ref(true)
      const now = new Date()
      const minDate = ref(new Date(new Date(now).setDate(now.getDate() + 2)))
      const is_pratica = ref()
      const pratica = ref()
      const isManualContract = ref()

      const responsiveLayout = ref()
      const views = ref()
      const checked_basic_view = ref()
      const changeViews = (event) => {
        views.value = event ? 'basic' : 'advanced'
        removeRowForType('ee')
        removeRowForType('gas')
        initManualRows()
      }
      const cliente = ref({
        nome_comune:''
      })
      const row_ee = ref({
        exist:false,
        code:'',
        iban:'',
        toponimo:'',
        indirizzo:'',
        civico:'',
        cap:'',
        provincia:'',
        comune:'',
        uso:'',
        offerta:null,
        addressEqual: true,
        citiesGrouped:[]
      })
      const row_gas = ref({
        exist:false,
        code:'',
        iban:'',
        toponimo:'',
        indirizzo:'',
        civico:'',
        cap:'',
        provincia:'',
        comune:'',
        uso:'Domestico',
        offerta:null,
        addressEqual: true,
        citiesGrouped:[],
        matricola:'',
        classe:null,
        destinazione:null,
        consumo: 0
      })
      const type_device_supply = {
        pod: 'ee',
        pdr: 'gas'
      }
      const visibleCodDistributori = ref(false)

      const cod_distributori = ref(["001","002","003","004","005","006","007","008","009","010","011","012","013","014","015","016","017","018","019","020","021","022","023","024","025","026","027","028","029","030","031","032","033","034","035","036","037","038","039","040","041","042","043","044","045","046","047","048","049","050","053","055","056","057","058","059","060","062","063","064","065","066","067","070","071","072","074","075","077","079","080","081","082","083","085","086","087","089","090","091","092","094","096","098","099","100","101","102","103","105","106","107","108","109","110","111","112","116","117","118","120","122","124","125","126","127","128","129","130","131","132","133","134","135","136","137","138","139","140","141","142","145","146","148","149","151","152","153","154","155","157","158","159","161","162","163","164","165","221","222","250","251","252", "253", "610"])

      onMounted(() => {
        usiEnergiaDomestico.value = usiEnergia.value.filter(uso => [3, 4].includes(uso.id))
        usiGasDomestico.value = usiGas.value.filter(uso => uso.id == 4)

        setIsPratica()
        getSlides()
        responsiveLayout.value = windowSize.value > breakpoint.value.replace('px','') ? 'scroll' : 'stack'
        typeClient.value = props.formData.typeClient       
        views.value = typeClient.value == 2 && !is_pratica.value ? 'basic' : 'advanced'
        checked_basic_view.value = views.value == 'basic'
        isManualContract.value = props.formData.contratto_manuale
        
        // setCf_attivazione()
        setMask()
        // setPiva_attivazione()
        setOfferte()
        getDistricts()
        getClassiGas()
        getCategorieGas()
        getRemi()
        getHeadings()
        setResponsive()
        refresh()
        const promises = [
          getCities(true),
          setSDI()
        ]
        emit("activeLoader", true)
        Promise.all(promises)
        .finally(()=>{
          emit("activeLoader", false)
        })
      })

      onUnmounted(() => {
        setResponsive()
      })
      const getSlides = async () => {
        await service.getSlides(stepIndex)
        .then((res) => {
          slides.value = res.items
          slides.value.forEach(slide => {
            slide.name = `/images/Step/step${stepIndex}-${slide.order}.png`
          })
        })
      }


      const isVisibleAddress = (index)=>{
        let is_visible = false
        if (manualRows.value[index].POD_PDR != "" && manualRows.value[index].POD_PDR.length == 14) {
          is_visible = (manualRows.value[index].TIPO == 'pod' && isManualContract.value && pratica.value != 's') ? false : true
        }          
        return is_visible
      }

      const setResponsive = () => {
        window.addEventListener('resize', () => {
          windowSize.value = window.innerWidth
          responsiveLayout.value = windowSize.value > breakpoint.value.replace('px','') ? 'scroll' : 'stack'
        })
      }
      // const setPiva_attivazione = () => {
      //   switch (typeClient.value) {
      //     case 3:
      //     case 2:
      //       has_piva_attivazione.value = false
      //       break;
      //   }
      // }
      // const setCf_attivazione = () => {
      //   switch (props.formData.pratica) {
      //     case 'v':
      //       has_cf_attivazione.value = true
      //       break;
      //   }
      // }
      const canGoToNextPage = computed (()=>{
        let next = false
        if (views.value == 'advanced') {
          next = activeTabIndex.value == 0 && manualRows.value.length > 0
        }else{
          next = row_ee.value.exist || row_gas.value.exist
        }
        return next
      })
      const refresh = () => {
        window.onbeforeunload = function() {
          emit('complete')
        };
      }
      const setMask = () => {
        switch (typeClient.value) {
          case 1:
          case 5:
          case 6:
          case 3:
            maskCF.value = '99999999999'
            break;
          case 2:
          case 4:
            maskCF.value = 'aaaaaa99a99a999a'
            break;
        }
      }

      const onInputCF_PIVA = (e, index, input) => {
        let value = input == 'CF_ATTIVAZIONE' || input == 'IBAN' ? e.target.value : e
        if (value) {
          manualRows.value[index][`${input}`] = value.toUpperCase()
        } else {
          delete manualRows.value[index][input]
        }
        delete validationErrors.value[`${input}_${index}`]
        delete validationErrors.value[`${input}_INVALID_${index}`]
        delete validationErrors.value[`${input}_equal_${index}`]
        delete validationErrors.value[`CF_PIVA_ATTIVAZIONE_NO_${index}`]
      }

      onUpdated(() => {
        setIsPratica()
        responsiveLayout.value = windowSize.value > breakpoint.value.replace('px','') ? 'scroll' : 'stack'
        typeClient.value = props.formData.typeClient
        // setCf_attivazione()
        setMask()
        // setPiva_attivazione()
        setOfferte()
        getDistricts()
        getClassiGas()
        getCategorieGas()
        getRemi()
        getHeadings()
        setResponsive()
        refresh()
        const promises = [
          getCities(true),
          setSDI()
        ]
        emit("activeLoader", true)
        Promise.all(promises)
        .finally(()=>{
          emit("activeLoader", false)
        })
      })
      const setIsPratica = () => {
        is_pratica.value = props.formData.pratica == null ? false : true
        pratica.value = props.formData.pratica
      }
      
      const setOfferte = () => {
        offerte.value = props.formData.offerte
        if (offerte.value == undefined || offerte.value.length == 0) {
          if (props.formData.agente) {
            getOfferteAgent()
          }else{
            getOfferte()
          }
        }
        setArrayOfferte()
      }

      const getOfferte = () => {
        service.getOfferte()
        .then((res) => {
          offerte.value = res.items
        })
      }
      const getOfferteAgent = () => {
        service.getOfferteAgent(props.formData.agente)
        .then((res) => {
          offerte.value = res.items
        })
      }

      const toggle = (event) => {
        op.value.toggle(event);
      }

      const openPosition = () => {
        visible.value = true;
      }

      const setArrayOfferte = () => {
        offerteEnergia.value = offerte.value ? offerte.value.filter((offerta) => offerta.tipologia === 1) : refresh();
        offerteGas.value = offerte.value ? offerte.value.filter((offerta) => offerta.tipologia !== 1) : refresh();
      }
      const downloadTemplate = () => {
        emit("activeLoader", true)
        service.downloadTemplateMultisito(headings, props.formData.agente)
        .finally(() => {
          emit("activeLoader", false)
        })
      }

      const addRow = async () => {
        if (manualRows.value.length > 200) {
          toast.add({severity:'warn', summary:'Importazione fallita', detail: `Non è possibile inserire più di 200 punti di fornitura`});
          return;
        } else {
          let newRow = headings.reduce((obj, key) => ({...obj, [key]: ''}), {});
          citiesGrouped.value[manualRows.value.length] = cities.value
          newRow.TIPO = ''
          if (typeClient.value != 3) {
            let i = manualRows.value.length
            let evt = {value:null}
            await onSelectedDistrict(i, evt)
            newRow.RAGIONE_SOCIALE = typeClient.value == 2 ? `${props.formData.cliente.nome} ${props.formData.cliente.cognome}` : props.formData.cliente.ragsoc
            newRow.CODICE_FISCALE_PIVA = props.formData.cliente.codfis != '' ? props.formData.cliente.codfis : props.formData.cliente.piva
            newRow.CODICE_SDI = props.formData.cliente.sdi
          }
          if (is_pratica.value) {
            newRow.DATA_DECORRENZA = minDate.value
          }
          manualRows.value.push(newRow)          
        }
      }

      const addRowForType = async (type) => {
        if (type == 'ee') {
          row_ee.value.exist = true
          if (offerteEnergia.value.length == 1) {
            row_ee.value.offerta = offerteEnergia.value[0].id 
          }
        }
        if (type == 'gas'){
          row_gas.value.exist = true
          if (offerteGas.value.length == 1) {
            row_gas.value.offerta = offerteGas.value[0].id 
          }
        }
      }
      const initManualRows = () => {
        manualRows.value = []
      }

      const removeRowForType = (type) => {
        if (type == 'ee') {
          row_ee.value = {
            exist:false,
            code:'',
            iban:'',
            toponimo:'',
            indirizzo:'',
            civico:'',
            cap:'',
            provincia:'',
            comune:'',
            uso:'',
            offerta:null,
            addressEqual: true,
            citiesGrouped:[]
          }
        }else{
          row_gas.value = {
            exist:false,
            code:'',
            iban:'',
            toponimo:'',
            indirizzo:'',
            civico:'',
            cap:'',
            provincia:'',
            comune:'',
            uso:'',
            offerta:null,
            addressEqual: true,
            citiesGrouped:[],
            matricola:'',
            classe:null,
            destinazione:null,
            consumo: 0
          }
        }
      }
      const deleteRow = (row, index) => {
        manualRows.value.splice(index, 1);
        validationErrors.value = []
        let properties = [
                'SIGLA_PROVINCIA_FORNITURA',
                'CITTA_FORNITURA',
                'USO',
                'OFFERTA'
              ] 
        let possibleDistricts = []
        cities.value.forEach(element => {
          possibleDistricts.push(element.provincia)
        });
        manualRows.value.forEach((row, i) => {
          let possibleCities = []
          let evt = {}
          evt.value = null
          let campo = null

          properties.forEach((proprieta) => {
            if (proprieta in row) {
              campo = row[proprieta]
              switch (proprieta) {
                case 'SIGLA_PROVINCIA_FORNITURA':
                  if (campo) {
                    if (!possibleDistricts.includes(campo)) {
                      validationErrors.value[`${proprieta}_IN_${i}`] = true
                    }else{
                      evt.value = campo
                      cities.value.forEach(element => {
                        if (element.provincia == campo) {
                          possibleCities.push(element.name)
                        }
                      });
                    }
                  }else{
                    validationErrors.value[`${proprieta}_${i}`] = true
                  }
                  onSelectedDistrict(i, evt)
                  break;
                  case 'CITTA_FORNITURA':
                    if (campo) {
                      if (validationErrors.value[`SIGLA_PROVINCIA_FORNITURA_${i}`]) {
                        onSelectedDistrict(i, evt)
                        validationErrors.value[`${proprieta}_NO_${i}`] = true
                      }else if (!possibleCities.includes(campo)) {
                        validationErrors.value[`${proprieta}_IN_${i}`] = true
                      }
                    }else{
                      validationErrors.value[`${proprieta}_${i}`] = true
                    }
                    
                    break;
                    case 'USO':
                    case 'OFFERTA':
                      if (!campo) {
                        validationErrors.value[`${proprieta}_${i}`] = true
                      }
                      break;
              }                                      
            }else{
              validationErrors.value[`${proprieta}_${i}`] = true
            }
          })
        });
      }
      
      const handleFileUpload = async (evt) => {
        fileToUpload.value = []
        evt.preventDefault()
        submitted.value = false;
        fileToUpload.value = self.refs.file.files
      }

      const getDistricts = () => {
          service.getDistricts().then((res) => {
            districts.value = res.items
          })
      }

      const onSelectedDistrict = async (i, evt = null, remove = null) => {
        if (evt.value) {
          await service.getCities(evt.value).then((res) => {
            res.items.forEach((city)=>{
              city.name = city.name.toUpperCase()
            })
            citiesGrouped.value[i] = res.items
          })          
        }else{
          citiesGrouped.value[i] = cities.value
        }
        if (remove) {
          delete manualRows.value[i].CITTA_FORNITURA
          delete validationErrors.value[`SIGLA_PROVINCIA_FORNITURA_${i}`];
          delete validationErrors.value[`SIGLA_PROVINCIA_FORNITURA_IN_${i}`];          
        }
      }
      const onSelectedDistrictForType = async (type, evt = null, remove = null) => {        
        await service.getCities(evt.value).then((res) => {
          res.items.forEach((city)=>{
            city.name = city.name.toUpperCase()
          })
          if (type == 'ee') {
            row_ee.value.citiesGrouped = res.items
          } 
          if (type == 'gas') {
            row_gas.value.citiesGrouped = res.items
          }
        })    
        removeError(type, 'SIGLA_PROVINCIA_FORNITURA')      
      }
      const removeError = (type, error) => {
        validationRowErrors.value[type][error] = false
        if (error == 'POD_PDR') {
          validationRowErrors.value[type][`${error}_EXISTENT`] = false
          validationRowErrors.value[type][`${error}_INVALID`] = false
        }
        if (type == 'ee' && error == 'USO') {
          row_ee.value.addressEqual = row_ee.value.uso == usiEnergiaDomestico.value[0].name ? true : false
          // if (row_ee.value.uso == usiEnergiaDomestico.value[0].name) {
          // }
        }
      }

      const onSelectedCity = (i) => {
        delete validationErrors.value[`CITTA_FORNITURA_${i}`];
        delete validationErrors.value[`CITTA_FORNITURA_IN_${i}`];
        delete validationErrors.value[`CITTA_FORNITURA_NO_${i}`];
        if(manualRows.value[i].TIPO == 'pdr' && pratica.value == 's'){
          delete remiOption.value[i]
          getRemi(manualRows.value[i].CITTA_FORNITURA, i)
        }
      }
      const onSelectedClasse = (i) => {
        delete validationErrors.value[`CLASSE_MISURATORE_${i}`];
        delete validationErrors.value[`CLASSE_MISURATORE_NO_${i}`];
      }

      const onSelectedDestinazione = (i) => {
        delete validationErrors.value[`DESTINAZIONE_USO_${i}`];
        delete validationErrors.value[`DESTINAZIONE_USO_NO_${i}`];
      }

      const onSelectedSdi = (i, evt) => {
        delete validationErrors.value[`CODICE_SDI_${i}`];
        delete validationErrors.value[`CODICE_SDI_NO_${i}`];
        if (evt.value == null) {
          delete manualRows.value[i].CODICE_SDI
        }
      }

      const onSelectedOfferta = (i) => {        
        delete validationErrors.value[`OFFERTA_${i}`];
        delete validationErrors.value[`OFFERTA_NO_${i}`];
      }

      const onSelectedUso = (i) => {
        delete validationErrors.value[`USO_${i}`];
        delete validationErrors.value[`USO_NO_${i}`];
      }
      const onSelectedRemi = (i) => {
        delete validationErrors.value[`REMI_${i}`];
      }
      const onInput = (evt, index, type) => {
        let value = evt
        if (type == 'MATRICOLA' || type == 'REMI') {
          value = evt.target.value.toUpperCase()
        }
        manualRows.value[index][`${type}`] = value
        delete validationErrors.value[`${type}_${index}`]
      }

      const getCities = async (set_client = false) => {
        await service.getCities()
        .then((res) => {
          res.items.forEach((city)=>{
            city.name = city.name.toUpperCase()
          })
          cities.value = res.items
          if (set_client) {
            cliente.value = props.formData.cliente  
            cliente.value.nome_comune = cities.value.find(city => city.id == cliente.value.comune_legale).name
          }         
        })
      }
      const getSDI = async () => {
        service.getSDI().then((res) => {
          sdi.value = res.items
        })
      }
      const getHeadings = () => {
        service.getHeadings().then((res) => {
          fields.value = res.items
        })
      }
      const setSDI = async () => {
        switch (typeClient.value) {
          case 1:
          case 3:
          case 4:
            await getSDI()
            break;
          default:
            sdi.value = [{sdi:'0000000'}]
            break;
        }
      }

      const importRowsFileToTable = async ()  => {
        if (fileToUpload.value.length > 0) {
          const data = await fileToUpload.value[0].arrayBuffer();
          const workbook = XLSX.read(data);
          let first_sheet_name = workbook.SheetNames[0];
          let ws = workbook.Sheets[first_sheet_name];
          const headers = (XLSX.utils.sheet_to_json(ws, { header: 1 }))[0];
          const sheet = XLSX.utils.sheet_to_json(ws, {skipHeader:true, raw:false});
          for (let index = 0; index < 8; index++) {
            headers.pop();
          }
          const isSameHeaders = JSON.stringify(headers) === JSON.stringify(headings);
          if (!isSameHeaders) {
            toast.add({severity:'warn', summary:'Importazione fallita', detail: `Sembra che i nomi delle colonne del file sono diversi dal template richiesto. Puoi scaricare il template da questa pagina`});
            return;
          }
          // Crea un nuovo array di sole righe che hanno la proprietà RAGIONE_SOCIALE
          const filteredRows = sheet.filter((row) => Object.prototype.hasOwnProperty.call(row, 'RAGIONE_SOCIALE'))
          filteredRows.forEach((row) => {
            Object.keys(row).forEach((key) => {
              if (Object.prototype.hasOwnProperty.call(row, key) && key.startsWith('__EMPTY')) {
                delete row[key];
              }
            });
          });
          if (filteredRows.length > 0) {
            if (filteredRows.length < 201) {
              try {
                manualRows.value = filteredRows

                let i = 2
                let possibleDistricts = []
                cities.value.forEach(element => {
                  possibleDistricts.push(element.provincia)
                });

                let array_no_indirizzo = [
                    'TOPONIMO_FORNITURA',
                    'INDIRIZZO_FORNITURA',
                    'CIVICO_FORNITURA',
                    'SIGLA_PROVINCIA_FORNITURA',
                    'CITTA_FORNITURA',
                    'CAP_FORNITURA',
                  ]

                manualRows.value.forEach((row, index) => {
                  row['COD_ESITO'] = null
                  let possibleCities = []
                  let evt = {}
                  evt.value = null
                  let uso = ''
                  let nomeUso = '';
                  let nomeOfferta = '';
                  let nomeClasse = '';
                  let nomeDestinazione = '';
                  let code_sdi = '';
                  let validation = []

                  if ('RAGIONE_SOCIALE' in row) {
                    row['RAGIONE_SOCIALE'] = row['RAGIONE_SOCIALE'].trim();
                  }
                  if ('CODICE_FISCALE_PIVA' in row) {
                    row['CODICE_FISCALE_PIVA'] = row['CODICE_FISCALE_PIVA'].trim();
                  }
                  if ('IBAN' in row) {
                    row['IBAN'] = row['IBAN'].replace(/\s/g, "");
                  }
                  if ('POD_PDR' in row) {
                    row['POD_PDR'] = row['POD_PDR'].trim();
                  }
                  if ('TOPONIMO_FORNITURA' in row) {
                    row['TOPONIMO_FORNITURA'] = row['TOPONIMO_FORNITURA'].trim();
                  }
                  if ('INDIRIZZO_FORNITURA' in row) {
                    row['INDIRIZZO_FORNITURA'] = row['INDIRIZZO_FORNITURA'].trim();
                  }
                  if ('CIVICO_FORNITURA' in row) {
                    row['CIVICO_FORNITURA'] = row['CIVICO_FORNITURA'].toString().trim();
                  }
                  if ('CAP_FORNITURA' in row) {
                    row['CAP_FORNITURA'] = row['CAP_FORNITURA'].toString().trim();
                  }
                  if ('SIGLA_PROVINCIA_FORNITURA' in row) {
                    row['SIGLA_PROVINCIA_FORNITURA'] = row['SIGLA_PROVINCIA_FORNITURA'].trim().toUpperCase();
                  }
                  if ('CITTA_FORNITURA' in row) {
                    row['CITTA_FORNITURA'] = row['CITTA_FORNITURA'].trim().toUpperCase()
                  }
                  if ('USO' in row) {
                    row['USO'] = row['USO'].trim();
                  }
                  if ('OFFERTA' in row) {
                    row['OFFERTA'] = row['OFFERTA'].trim();
                  }
                  if ('CONSUMO_ANNUO' in row) {
                    row['CONSUMO_ANNUO'] = row['CONSUMO_ANNUO'].toString().trim();
                  }
                  if ('CODICE_SDI' in row) {
                    row['CODICE_SDI'] = row['CODICE_SDI'].toString().trim();
                  }
                  
                  if (row['POD_PDR'].startsWith('IT')) {
                    validation = [...validation_pod.value]
                    if (isManualContract.value) {
                      array_no_indirizzo.forEach((el)=>{
                        let indice = validation.indexOf(el)
                        validation.splice(indice, 1)
                        delete row[el]
                      })
                    }
                  }else{
                    validation = [...validation_pdr.value]
                  }
                  
                  if(validation.includes('CONSUMO_ANNUO')){
                    let indice = validation.indexOf('CONSUMO_ANNUO')
                    validation.splice(indice, 1)
                    row.CONSUMO_ANNUO = row['CONSUMO_ANNUO'] ?? '0'
                  }
                  validation.forEach((element)=>{
                    let campo = null
                    let existProperty = element in row
                    if (!existProperty) {
                      validationErrors.value[`${element}_${index}`] = true
                    }else{
                      campo = sheet[index][element]
                      if (campo) {
                        switch (element) {
                          case 'CODICE_SDI':
                                code_sdi = ws['C'+i].w
                                row.CODICE_SDI = sdi.value ? sdi.value.find(el => el.sdi === code_sdi.trim()) : '';     
                                if (!row.CODICE_SDI) {
                                  validationErrors.value[`${element}_${index}`] = true
                                }
                              break;
                          case 'POD_PDR':
                            usiOption.value[index] = manualRows.value[index]['POD_PDR'].startsWith('IT')
                            ? usiEnergia.value.filter(uso => getUsiForTypeClient('Energia').includes(uso.id))
                            : usiGas.value.filter(uso => getUsiForTypeClient('Gas').includes(uso.id))

                            offerteOption.value[index] = row['POD_PDR'].startsWith('IT') ? offerteEnergia.value : offerteGas.value     
                            manualRows.value[index].TIPO = row['POD_PDR'].startsWith('IT') ? 'pod' : 'pdr'
                            break;
                          case 'SIGLA_PROVINCIA_FORNITURA':
                              if (!possibleDistricts.includes(campo)) {
                                validationErrors.value[`${element}_IN_${index}`] = true
                                row.SIGLA_PROVINCIA_FORNITURA = ''
                              }else{
                                evt.value = campo
                                cities.value.forEach(citta => {
                                  if (citta.provincia == campo) {
                                    possibleCities.push(citta.name.toUpperCase())
                                  }
                                });
                              }
                            onSelectedDistrict(index, evt)
                            break;
                          case 'CITTA_FORNITURA':
                              if (validationErrors.value[`SIGLA_PROVINCIA_FORNITURA_${index}`]) {
                                onSelectedDistrict(i, evt)
                                validationErrors.value[`${element}_NO_${index}`] = true
                                row.CITTA_FORNITURA = ''
                              }else if (!possibleCities.includes(campo)) {
                                validationErrors.value[`${element}_IN_${index}`] = true
                                row.CITTA_FORNITURA = ''
                              }
                            break;
                          case 'USO':
                              uso = ws['L'+i].v.split(' - ');
                              nomeUso = uso[1];
                              row.USO = usiOption.value[index] ? usiOption.value[index].find(uso => uso.name === nomeUso.trim()) : '';     
                              if (!row.USO) {
                                validationErrors.value[`${element}_NO_${index}`] = true
                              }else{
                                row.USO = row.USO.name
                              }
                            break;
                          case 'OFFERTA':
                              nomeOfferta = ws['M'+i].v;
                              row.OFFERTA = offerteOption.value[index] ? offerteOption.value[index].find(offerta => offerta.name.trim() === nomeOfferta.trim()) : '';     
                              if (!row.OFFERTA) {
                                validationErrors.value[`${element}_NO_${index}`] = true
                              }else{
                                row.OFFERTA = row.OFFERTA.id
                              }
                            break;
                          case 'CLASSE_MISURATORE':
                            nomeClasse = ws['O'+i].v;
                            row.CLASSE_MISURATORE = classeOption.value.find(classeOp => classeOp.classe.trim() === nomeClasse.trim())   
                            if (!row.CLASSE_MISURATORE) {
                              validationErrors.value[`${element}_NO_${index}`] = true
                            }
                          break;
                          case 'DESTINAZIONE_USO':
                            nomeDestinazione = ws['P'+i].v;
                            row.DESTINAZIONE_USO = destinazioneOption.value.find(destinazione => destinazione.name.trim() === nomeDestinazione.trim())     
                            if (!row.DESTINAZIONE_USO) {
                              validationErrors.value[`${element}_NO_${index}`] = true
                            }
                          break;
                        }                  
                      }else{
                        validationErrors.value[`${element}_${index}`] = true
                      }
                    }
                  })
                  if (typeClient.value == 2) {
                    if ('CODICE_SDI' in row) {
                      delete manualRows.value[index].CODICE_SDI
                    }
                  }
                  if (typeClient.value != 3) {
                    manualRows.value[index].RAGIONE_SOCIALE = typeClient.value == 2 ? `${props.formData.cliente.nome} ${props.formData.cliente.cognome}` : props.formData.cliente.ragsoc
                    manualRows.value[index].CODICE_FISCALE_PIVA = props.formData.cliente.codfis ?? props.formData.cliente.piva
                    manualRows.value[index].CODICE_SDI = props.formData.cliente.sdi 
                    delete validationErrors.value[`RAGIONE_SOCIALE_${index}`]
                    delete validationErrors.value[`CODICE_FISCALE_PIVA_${index}`]
                    delete validationErrors.value[`CODICE_SDI_${index}`]
                  }
                  i++
                });
                activeTabIndex.value = 0
              } catch (error) {
                toast.add({severity:'warn', summary:'Importazione fallita', detail: error, life:5000});
              }
            }else{
              toast.add({severity:'warn', summary:'Importazione fallita', detail: 'Non è possibile inserire più di 200 punti di fornitura', life:5000});
            }
          } else {
            toast.add({severity:'warn', summary:'Importazione fallita', detail: 'Il primo foglio di lavoro è vuoto', life:5000});
          }
        }
      }
      const set_rowsType_to_manualRows = (type_supply) => {
        let newRow = headings.reduce((obj, key) => ({...obj, [key]: ''}), {});

        newRow.TIPO = type_supply
        newRow.RAGIONE_SOCIALE = `${cliente.value.nome} ${cliente.value.cognome}`
        newRow.CODICE_FISCALE_PIVA = cliente.value.codfis
        newRow.CODICE_SDI = cliente.value.sdi
        if (row_ee.value.addressEqual) {
          newRow.TOPONIMO_FORNITURA = cliente.value.toponimo_legale
          newRow.INDIRIZZO_FORNITURA = cliente.value.indirizzo_legale
          newRow.CIVICO_FORNITURA = cliente.value.civico_legale
          newRow.CAP_FORNITURA = cliente.value.cap_legale
          newRow.CITTA_FORNITURA = cliente.value.nome_comune
          newRow.SIGLA_PROVINCIA_FORNITURA = cities.value.find(cities => cities.id == cliente.value.comune_legale).provincia
        } else {
          if (type_supply == 'pod') {
            newRow.TOPONIMO_FORNITURA = row_ee.value.toponimo
            newRow.INDIRIZZO_FORNITURA = row_ee.value.indirizzo
            newRow.CIVICO_FORNITURA = row_ee.value.civico
            newRow.CAP_FORNITURA = row_ee.value.cap
            newRow.CITTA_FORNITURA = row_ee.value.comune
            newRow.SIGLA_PROVINCIA_FORNITURA = row_ee.value.provincia

          }else{
            newRow.TOPONIMO_FORNITURA = row_gas.value.toponimo
            newRow.INDIRIZZO_FORNITURA = row_gas.value.indirizzo
            newRow.CIVICO_FORNITURA = row_gas.value.civico
            newRow.CAP_FORNITURA = row_gas.value.cap
            newRow.CITTA_FORNITURA = row_gas.value.comune
            newRow.SIGLA_PROVINCIA_FORNITURA = row_gas.value.provincia
          }
        }
        if (type_supply == 'pod') {
          newRow.IBAN = row_ee.value.iban.toUpperCase()
          newRow.POD_PDR = row_ee.value.code
          newRow.USO = row_ee.value.uso ?? null
          newRow.OFFERTA = row_ee.value.offerta ?? null
          // if (row_ee.value.uso) {
          // }
          // if (row_ee.value.offerta) {
          // }
        }else{
          newRow.IBAN = row_gas.value.iban.toUpperCase()
          newRow.POD_PDR = row_gas.value.code
          newRow.USO = row_gas.value.uso ?? null
          newRow.OFFERTA = row_gas.value.offerta ?? null
          // if (row_gas.value.uso) {
          // }
          // if (row_gas.value.offerta) {
          // }
          newRow.MATRICOLA = row_gas.value.matricola,
          newRow.CLASSE_MISURATORE = row_gas.value.classe
          newRow.DESTINAZIONE_USO = row_gas.value.destinazione
          newRow.CONSUMO_ANNUO = row_gas.value.consumo
        }
        newRow.POD_PDR_INVALID = false
        manualRows.value.push(newRow)          
      }
      const nextPage = async () => {
        toast.removeGroup('failPrecheck')
        toast.removeGroup('PrecheckKO')
        submitted.value = true;
        prechecked.value = false
        // Object.keys(validationErrors.value).length > 0) {
        //   toast.add({severity:'warn', summary:'Campi Errati', detail: 'Alcuni campi sembrano vuoti o errati', life:3500});
        // }else 
        if (views.value == 'basic') {
          initManualRows()
          if (row_ee.value.exist) {
            set_rowsType_to_manualRows('pod')
          }
          if (row_gas.value.exist) {
            set_rowsType_to_manualRows('pdr')
          }
        }
        
        if(await validateForm()){
          emit('activeLoader', true)
          // manualRows.value.forEach((row, i)=>{
          //   if ('VOLTURA' in manualRows.value[i]) {
          //     if(!manualRows.value[i].VOLTURA){
          //       delete manualRows.value[i].CODICE_FISCALE_PIVA_PRECEDENTE
          //       delete manualRows.value[i].VOLTURA
          //     }
          //   }
          //   if ('SUBENTRO' in manualRows.value[i]) {
          //     if(!manualRows.value[i].SUBENTRO){
          //       delete manualRows.value[i].SUBENTRO
          //     }
          //   }
          // })
          let dataPrecheck = 0
          // let dataPrecheck = await getPrecheckIsValid(manualRows.value)

          if (dataPrecheck.length > 0) {
            prechecked.value = true
            let precheckErrors = []
            let esiti = []
            let cod_esiti = []
            array_esito.value = []

            dataPrecheck.forEach(data => {
              if ('generic' in data.errors) {
                precheckErrors.push(data.errors)
              }else{
                let code = 'COD_'+data.type.toUpperCase()
                data.response.forEach((response, i) => {
                  let subentro = manualRows.value.find(row => row.POD_PDR === response[`COD_${data.type.toUpperCase()}`]).SUBENTRO
                  let indexRow = manualRows.value.findIndex(row => row.POD_PDR === response[`COD_${data.type.toUpperCase()}`])
                  if (data.type == 'pdr' && Boolean(parseInt(response.ESITO))) {
                    manualRows.value[indexRow].REMI = response.COD_REMI
                  }
                  if (subentro) {
                    response.DETTAGLIO_ESITO = ''
                    response.COD_ESITO = ''
                    response.ESITO = '1'
                  }
                  let objCode = {
                    fornitura:response[code],
                    dettaglio_esito:response.DETTAGLIO_ESITO,
                    cod_esito:response.COD_ESITO,
                    esito: Boolean(parseInt(response.ESITO)),
                  }
                  array_esito.value.push(objCode)
                  if (response.COD_ESITO != '') {
                    cod_esiti.push(response.COD_ESITO)
                  }                    
                })
              }
            })
            // cod_esiti = new Set(cod_esiti);
            cod_esiti.forEach((code)=>{
              if(!esiti_noprecheck.value.includes(code)){
                esiti.push(code)
              }
            })
            if (precheckErrors.length == 0) {
              if (esiti.length > 0) {
                toast.add({severity:'warn', summary:'Campi Errati', detail: 'Precheck in KO', group: 'PrecheckKO'});
                manualRows.value.forEach((el, i)=>{
                  if (array_esito.value.find(obj => obj.fornitura === manualRows.value[i].POD_PDR)) {
                    manualRows.value[i].DETTAGLIO_ESITO = array_esito.value.find(obj => obj.fornitura === manualRows.value[i].POD_PDR).dettaglio_esito;
                    manualRows.value[i].COD_ESITO = array_esito.value.find(obj => obj.fornitura === manualRows.value[i].POD_PDR).cod_esito; 
                    manualRows.value[i].PRECHECKOK = array_esito.value.find(obj => obj.fornitura === manualRows.value[i].POD_PDR).esito
                  }else{
                    manualRows.value[i].DETTAGLIO_ESITO = ''
                    manualRows.value[i].COD_ESITO = ''
                    manualRows.value[i].PRECHECKOK = true
                  }
                  if (manualRows.value[i].DETTAGLIO_ESITO != '') {
                    let type_field_error = ''
                    switch (manualRows.value[i].COD_ESITO) {
                      case '001':
                      case '004':
                      case '101':
                      case '102':
                        type_field_error = 'POD_PDR'
                        break;
                      case '002':
                      case '003':
                      case '103':
                      case '910':
                      case '107':
                      case '201':
                        type_field_error = 'CODICE_FISCALE_PIVA'
                        break;
                      case '006':
                      case '922':
                      case '104':
                        type_field_error = 'CODICE_FISCALE_PIVA_PRECEDENTE'
                        break;
                    }
                    validationErrors.value[`${type_field_error}_precheck_${i}`] = true
                  }
                  if (manualRows.value[i].TIPO == 'pdr') {
                    getRemi(manualRows.value[i].CITTA_FORNITURA, i)
                  }
                })
              }else{
                manualRows.value.forEach((row, i)=>{
                  manualRows.value[i].PRECHECKOK = true
                })
                toast.add({severity:'success', summary:'Precheck completato', group: 'PrecheckOK'});
                setTimeout(()=>{
                  toast.removeGroup('PrecheckOK')
                  emit('next-page', {
                    formData: 
                    {
                      "manualRows": manualRows.value,
                      "type_matrice": type_matrice.value,
                    }
                    ,pageIndex: stepIndex
                  });            
                }, 3000)
              }
            }else{
              let errors = []
              precheckErrors.forEach((typeErr)=>{
                typeErr.details.forEach((err, i)=>{
                  errors.push(err)
                })
              })
                
              manualRows.value.forEach((el, i)=>{
                if (errors.find(obj => obj.fornitura === manualRows.value[i].POD_PDR)) {
                  manualRows.value[i].DETTAGLIO_ESITO = errors.find(obj => obj.fornitura === manualRows.value[i].POD_PDR).ERRORE;
                  if (manualRows.value[i].DETTAGLIO_ESITO != '') {
                    validationErrors.value[`POD_PDR_precheck_${i}`] = true
                  }                
                }
              })

              toast.add({severity:'warn', summary:'Campi Errati', detail: 'Precheck fallito', group: 'failPrecheck'});
            }
          }else{
            manualRows.value.forEach((row, i)=>{
              manualRows.value[i].PRECHECKOK = true
              if (is_pratica.value) {
                manualRows.value[i].DATA_DECORRENZA = setData(manualRows.value[i].DATA_DECORRENZA)
              }
            })
            // toast.add({severity:'warn', summary:'Precheck inattivo', group: 'inactivePrecheck'});
            toast.removeGroup('inactivePrecheck')
            emit('next-page', {
              formData: 
              {
                "manualRows": manualRows.value,
                "type_matrice": type_matrice.value,
              }
              ,pageIndex: stepIndex
            });            
          }
        }
      }
      const setData = (el) => {
        let giorno = el.getDate() 
        let mese = el.getMonth()+1
        mese = mese.toString().padStart(2,'0')
        let anno = el.getFullYear() 
        el = `${anno}-${mese}-${giorno}`
        return el
      } 

      const getPrecheckIsValid = async (data) => {
        toast.add({severity:'info', summary: `Precheck in corso...`, detail: `Tempo di attesa stimato di 2 minuti`, group: 'tc'});
        emit('activeLoader', true)
        return service.getPrecheck(data)
        .then((res)=>{
          toast.removeGroup('tc')
          emit('activeLoader', false)
          return res.item
        })
      }

      const getRemi = (city = null, id = null) => {
        service.getRemi(city).then((res) => {
          if (!city) {
            totalRemi.value = res.items
          }else{
            remiOption.value[id] = res.items
          }
        })
      }

      const getClassiGas = () => {
        service.getClassiGas().then((res) => {
          res.items.forEach((item)=>{
            item.classe = item.classe.replace(',','.')
          })
          classeOption.value = res.items
        })
      }

      const getCategorieGas = () => {
        service.getCategorieGas().then((res) => {
          destinazioneOption.value = res.items
        })
      }

      const prevPage = () => {
        // Object.keys(props.formData.contatto).forEach(el => {
        //   delete props.formData.contatto[el]
        // });
        if (typeClient.value == 2) {
          stepIndex = 1
        }
        emit('prev-page', {pageIndex: stepIndex});
      }

      const onCellEditInit = (event) => {
        let {field, index} = event
        if (field != 'OFFERTA' && field != 'CODICE_SDI') {
          if (manualRows.value[index].POD_PDR != '') {
            if (manualRows.value[index].POD_PDR == 'pdr' || (manualRows.value[index].POD_PDR == 'pod' && !isManualContract.value)) {
              nextTick(() => {
                document.getElementById(`${field}_${index}`).focus();
              })                      
            }
          }
        }
      }

      const onCellEditComplete = (event) => {
        let {field, index} = event
        delete validationErrors.value[`${field}_${index}`];
        if (field === 'POD_PDR') {
          manualRows.value[index]['POD_PDR'] = manualRows.value[index]['POD_PDR'].toUpperCase()
          delete validationErrors.value[`USO_NO_${index}`];
          delete validationErrors.value[`OFFERTA_NO_${index}`];
          delete validationErrors.value[`${field}_existent_${index}`];
          delete validationErrors.value[`${field}_exist_${index}`];
          delete validationErrors.value[`${field}_precheck_${index}`];
          delete validationErrors.value[`${field}_INVALID_${index}`];

          if (!manualRows.value[index].PRECHECKOK) { 
            usiOption.value[index] = []
            offerteOption.value[index] = []
            if (manualRows.value[index][field] != '') {
              usiOption.value[index] = manualRows.value[index]['POD_PDR'].startsWith('IT')
              ? usiEnergia.value.filter(uso => getUsiForTypeClient('Energia').includes(uso.id))
              : usiGas.value.filter(uso => getUsiForTypeClient('Gas').includes(uso.id))

              offerteOption.value[index] = manualRows.value[index]['POD_PDR'].startsWith('IT') ? offerteEnergia.value : offerteGas.value          
            }
            if (manualRows.value[index]['USO'] != '' && manualRows.value[index]['USO'] != null) {
              manualRows.value[index]['USO'] = usiOption.value[index].find(option => option.name ==  manualRows.value[index]['USO']) ? manualRows.value[index]['USO'] : ''
            }

            
            if (usiOption.value[index].length == 1) {
              manualRows.value[index]['USO'] = usiOption.value[index][0].name
            }
            
            if (manualRows.value[index]['OFFERTA'] != '' && manualRows.value[index]['OFFERTA'] != null) {
              manualRows.value[index]['OFFERTA'] = offerteOption.value[index].find(option => option.id ==  manualRows.value[index]['OFFERTA']) ? manualRows.value[index]['OFFERTA'] : ''
            }
            
            if (offerteOption.value[index].length == 1) {
              manualRows.value[index]['OFFERTA'] = offerteOption.value[index][0].id
            }
          }
          manualRows.value[index].TIPO = manualRows.value[index]['POD_PDR'].startsWith('IT') ? 'pod' : 'pdr'
          if (manualRows.value[index].POD_PDR != '') {
            let is_visible = isVisibleAddress(index)
            if (is_visible) {
              if (typeClient.value != 3) {
                manualRows.value[index].TOPONIMO_FORNITURA = props.formData.cliente.toponimo_legale
                manualRows.value[index].INDIRIZZO_FORNITURA = props.formData.cliente.indirizzo_legale
                manualRows.value[index].CIVICO_FORNITURA = props.formData.cliente.civico_legale
                manualRows.value[index].CAP_FORNITURA = props.formData.cliente.cap_legale
                manualRows.value[index].SIGLA_PROVINCIA_FORNITURA = citiesGrouped.value[index].find(cities => cities.id == props.formData.cliente.comune_legale).provincia
                citiesGrouped.value[index] = cities.value.filter(city => city.provincia == manualRows.value[index].SIGLA_PROVINCIA_FORNITURA)
                manualRows.value[index].CITTA_FORNITURA = citiesGrouped.value[index].find(cities => cities.id == props.formData.cliente.comune_legale).name

                if(manualRows.value[index].TIPO == 'pdr' && pratica.value == 's'){
                  delete remiOption.value[index]
                  getRemi(manualRows.value[index].CITTA_FORNITURA, index)
                }
              }
            }
            delete validationErrors.value[`TOPONIMO_FORNITURA_${index}`];
            delete validationErrors.value[`INDIRIZZO_FORNITURA_${index}`];
            delete validationErrors.value[`CIVICO_FORNITURA_${index}`];
            delete validationErrors.value[`CAP_FORNITURA_${index}`];
            delete validationErrors.value[`SIGLA_PROVINCIA_FORNITURA_${index}`];
            delete validationErrors.value[`CITTA_FORNITURA_${index}`];
            
          }

        } else if(field == 'CODICE_FISCALE_PIVA'){
          delete validationErrors.value[`${field}_precheck_${index}`];
        }else if(field == 'CODICE_FISCALE_PIVA_PRECEDENTE'){
          delete validationErrors.value[`${field}_INVALID_${index}`];
          delete validationErrors.value[`${field}_${index}`];
        }
      }
      const getUsiForTypeClient = (type) => {
        return eval(`possible_usi${type}_for_typeClient`).value.find(poss_uso => poss_uso.typeClient == typeClient.value).id_usi
      }

      const removeFile = () => {
        keyInputReset.value = new Date()
        fileToUpload.value = []
      }

          
      const deleteAllRows = () => {
        manualRows.value = []
        validationErrors.value = []
        usiOption.value = []
        remiOption.value = []
        offerteOption.value = []
        prechecked.value = false
      }
       
      const validateForm = async () => {
        initValidationRowErrors() 
        let regexIban = /^(IT\d{2}[A-Z]\d{10}[0-9A-Z]{12})$/;
        let regexCf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
        let regexPiva = /^[0-9]{11}$/
        let regexPod = /^IT\d{3}E\d{7}[\dA]$/;
        let regexPdr = /^\d{14}$/;
        validationErrors.value = []
        type_matrice.value = ''
        
        let validOfferta = null
        let type_device = [];
        let supply = {
          pod:[],
          pdr:[]
        }
        manualRows.value.forEach((el, i) => {
          el.FREQUENZA = props.formData.frequenza
          let validation = el.TIPO == 'pod' ? Array.from(new Set(validation_pod.value)) : Array.from(new Set(validation_pdr.value))
          if(pratica.value != null){
            validation.push('DATA_DECORRENZA')  
            if (pratica.value == 'v') {
              validation.push('CF_ATTIVAZIONE')
            }else if(el.TIPO == 'pdr'){
              validation.push('REMI')
            }
          }

          if (el.TIPO == 'pod' && !isVisibleAddress(i)) {
            let array_validation_pod = [
            'TOPONIMO_FORNITURA',
            'INDIRIZZO_FORNITURA',
            'CIVICO_FORNITURA',
            'CAP_FORNITURA',
            'SIGLA_PROVINCIA_FORNITURA',
            'CITTA_FORNITURA'
            ]
            array_validation_pod.forEach(el => {
              let index = validation.findIndex(field => field == el)
              if (index) {
                validation.splice(index,1)
              }
            });
          }

          switch (typeClient.value) {
            case 1:
            case 3:
            case 4:
              validation.push('CODICE_SDI');
              break;
            default:
              manualRows.value[i].CODICE_SDI = '0000000'
              break;
          }
          Object.keys(el).forEach((prop) => {
            type_device[i] = el['TIPO'];
            if (prop == 'RAGIONE_SOCIALE') {
              if (el[prop].trim() == '') {
                validationErrors.value[`${prop}_${i}`] = true
              } else {
                delete validationErrors.value[`${prop}_${i}`];
              }
            }
            if (prop == 'CODICE_FISCALE_PIVA') {
              if (el[prop].toString().length == 11) {
                if (!regexPiva.test(el[prop])) {
                  validationErrors.value[`${prop}_${i}`] = true
                } else {
                  delete validationErrors.value[`${prop}_${i}`];
                }
              } else if (el[prop].toString().length == 16) {
                if (!regexCf.test(el[prop])) {
                  validationErrors.value[`${prop}_${i}`] = true
                } else {
                  delete validationErrors.value[`${prop}_${i}`];
                }
              } else {
                validationErrors.value[`${prop}_${i}`] = true
              }
            }
            if (prop == 'CF_ATTIVAZIONE') {
              if (el[prop] != '' && el[prop] != null) {
                delete validationErrors.value[`${prop}_${i}`];
                // let regex = (typeClient.value == 2 || typeClient.value == 4) ? regexCf : regexPiva
                let regex
                if (el[prop].length < 11 || el[prop].length > 16) {
                  validationErrors.value[`${prop}_INVALID_${i}`] = true
                }else{
                  if (el[prop].length == 16) {
                    regex = regexCf
                  }else if(el[prop].length == 11){
                    regex = regexPiva
                  }

                  if (!regex.test(el[prop])) {
                    validationErrors.value[`${prop}_INVALID_${i}`] = true
                  } else {
                    delete validationErrors.value[`${prop}_INVALID_${i}`];
                  }      
                  
                  if (el[prop] == el.CODICE_FISCALE_PIVA) {
                    validationErrors.value[`${prop}_equal_${i}`] = true
                  } else {
                    delete validationErrors.value[`${prop}_equal_${i}`];
                  }      
                } 
              } else {
                validationErrors.value[`${prop}_${i}`] = true
              }
            }
            if (prop == 'PIVA_ATTIVAZIONE') {
              if (el[prop] != '' && el[prop] != null) {
                delete validationErrors.value[`${prop}_${i}`];

                if (!regexPiva.test(el[prop])) {
                  validationErrors.value[`${prop}_INVALID_${i}`] = true
                } else {
                  delete validationErrors.value[`${prop}_INVALID_${i}`];

                  if (el[prop] == el.CODICE_FISCALE_PIVA) {
                    validationErrors.value[`${prop}_equal_${i}`] = true
                  } else {
                    delete validationErrors.value[`${prop}_equal_${i}`];
                  }
                }              
              } else {
                validationErrors.value[`${prop}_${i}`] = true
                // if (el.PIVA_ATTIVAZIONE.length == 0) {
                // }
              }
            }
            if (prop == 'DATA_DECORRENZA') {
              if (el[prop] != '' && el[prop] != null) {
                delete validationErrors.value[`${prop}_${i}`];
              } else {
                validationErrors.value[`${prop}_${i}`] = true
              }
            }
            if (prop == 'IBAN' && el['IBAN'] > '') {              
              if (!regexIban.test(el[prop])) {
              validationErrors.value[`${prop}_${i}`] = true
              } else {
              delete validationErrors.value[`${prop}_${i}`];
              }
            }
            if (prop == 'MATRICOLA') {
              if (el[prop] == '') {
              validationErrors.value[`${prop}_${i}`] = true
              } else {
              delete validationErrors.value[`${prop}_${i}`];
              }
            }
            if (prop == 'CONSUMO_ANNUO' && ('SUBENTRO' in el && !el['SUBENTRO'])) {
              if (el[prop] == '') {
              validationErrors.value[`${prop}_${i}`] = true
              } else {
              delete validationErrors.value[`${prop}_${i}`];
              }
            }
            if (prop == 'CLASSE_MISURATORE') {
              if (!el[prop] || el[prop].length == 0) {
              validationErrors.value[`${prop}_${i}`] = true
              } else {
              delete validationErrors.value[`${prop}_${i}`];
              }
            }
            if (prop == 'DESTINAZIONE_USO') {
              if (!el[prop] || el[prop].length == 0) {
              validationErrors.value[`${prop}_${i}`] = true
              } else {
              delete validationErrors.value[`${prop}_${i}`];
              }
            }
            if (prop == 'POD_PDR') {
              if (el['POD_PDR'] != '') {
                if (regexPod.test(el[prop]) || regexPdr.test(el[prop])) {
                  el.TIPO = regexPod.test(el[prop]) ? 'pod' : 'pdr'
                  if (regexPod.test(el[prop]) && !verificaCodiceDistributore(el[prop])) {
                    validationErrors.value[`${prop}_INVALID_${i}`] = true
                  }
                  if (supply[el.TIPO].includes(el.POD_PDR)) {
                    validationErrors.value[`${prop}_existent_${i}`] = true;
                  }
                  supply[el.TIPO].push(el.POD_PDR)
                  validOfferta = el.TIPO == 'pod' ? 1 : 2
                  delete validationErrors.value[`${prop}_${i}`];
                } else {
                  validationErrors.value[`${prop}_INVALID_${i}`] = true
                }                  
              }else{
                validationErrors.value[`${prop}_${i}`] = true
              }
            }
            if (prop == 'CODICE_SDI') {
              if (el['CODICE_SDI'] != '') {
                if (sdi.value.find((code) => code.sdi == el[prop])) {
                  delete validationErrors.value[`${prop}_NO_${i}`]
                }else{
                  validationErrors.value[`${prop}_NO_${i}`] = true
                }              
              } else {
                validationErrors.value[`${prop}_${i}`] = true
              }
            }
            if (prop == 'REMI') {
              if (el[prop] != '' && el[prop] != null) {
                delete validationErrors.value[`${prop}_${i}`];
                if (el[prop].toString().length == 8 && el[prop].toString() != '00000000') {
                  delete validationErrors.value[`${prop}_ERROR_${i}`];
                } else {
                  validationErrors.value[`${prop}_ERROR_${i}`] = true
                }              
              } else {
                validationErrors.value[`${prop}_${i}`] = true
              }
            }
            // switch (prop) {
            //   case 'TOPONIMO_FORNITURA':
            //   case 'INDIRIZZO_FORNITURA':
            //   case 'CIVICO_FORNITURA':
            //   case 'CAP_FORNITURA':
            //     el[prop] = el[prop].toString()
            //     if (el[prop].trim() == '') {
            //       validationErrors.value[`${prop}_${i}`] = true
            //     } else {
            //       delete validationErrors.value[`${prop}_${i}`];
            //     }
            //     break;
            // }
          });
          validation.forEach((element)=>{
            let existProperty = element in el
            if (!existProperty) {
              validationErrors.value[`${element}_${i}`] = true
            }else{
              switch (element) {
                case 'USO':
                  if (el.USO.length == 0) {
                    validationErrors.value[`${element}_${i}`] = true
                  }
                  break;
                case 'OFFERTA':
                  if (!el.OFFERTA || el.OFFERTA.length == 0) {
                    validationErrors.value[`${element}_${i}`] = true
                  } 
                break;
                case 'CLASSE_MISURATORE':
                  if (!el.CLASSE_MISURATORE || el.CLASSE_MISURATORE.length == 0) {
                    validationErrors.value[`${element}_${i}`] = true
                  }
                  break;
                case 'DESTINAZIONE_USO':
                  if (!el.DESTINAZIONE_USO || el.DESTINAZIONE_USO.length == 0) {
                    validationErrors.value[`${element}_${i}`] = true
                  }
                  break;
                case 'CODICE_SDI':
                  if (el.CODICE_SDI.trim().length == 7) {
                    delete validationErrors.value[`${element}_${i}`];
                    delete validationErrors.value[`${element}_NO_${i}`];
                  } else {
                    if (el.CODICE_SDI.trim() != '') {
                      validationErrors.value[`${element}_NO_${i}`] = true
                    } else {
                      validationErrors.value[`${element}_${i}`] = true
                    }
                  }
                  break;
                case 'CF_ATTIVAZIONE':
                  if (el.CF_ATTIVAZIONE != '' && el.CF_ATTIVAZIONE != null) {
                    delete validationErrors.value[`CF_ATTIVAZIONE_${i}`];

                    let regex 
                    if (el.CF_ATTIVAZIONE.length < 11 || el.CF_ATTIVAZIONE.length > 16) {
                      validationErrors.value[`CF_ATTIVAZIONE_INVALID_${i}`] = true
                    }else{
                      if (el.CF_ATTIVAZIONE.length == 16) {
                        regex = regexCf
                      }else if(el.CF_ATTIVAZIONE.length == 11){
                        regex = regexPiva
                      }
                      
                      if (!regex.test(el.CF_ATTIVAZIONE)) {
                        validationErrors.value[`CF_ATTIVAZIONE_INVALID_${i}`] = true
                      } else {
                        delete validationErrors.value[`CF_ATTIVAZIONE_INVALID_${i}`];
                      }      
                      
                      if (el.CF_ATTIVAZIONE == el.CODICE_FISCALE_PIVA) {
                        validationErrors.value[`CF_ATTIVAZIONE_equal_${i}`] = true
                      } else {
                        delete validationErrors.value[`CF_ATTIVAZIONE_equal_${i}`];
                      }      
                    }
                  } else {
                    validationErrors.value[`CF_ATTIVAZIONE_${i}`] = true
                  }
                  break;
                case 'PIVA_ATTIVAZIONE':
                  if (el.PIVA_ATTIVAZIONE != '' && el.PIVA_ATTIVAZIONE != null) {
                    delete validationErrors.value[`PIVA_ATTIVAZIONE_${i}`];

                    if (!regexPiva.test(el.PIVA_ATTIVAZIONE)) {
                      validationErrors.value[`PIVA_ATTIVAZIONE_INVALID_${i}`] = true
                    } else {
                      delete validationErrors.value[`PIVA_ATTIVAZIONE_INVALID_${i}`];

                      if (el.PIVA_ATTIVAZIONE == el.CODICE_FISCALE_PIVA) {
                        validationErrors.value[`PIVA_ATTIVAZIONE_equal_${i}`] = true
                      } else {
                        delete validationErrors.value[`PIVA_ATTIVAZIONE_equal_${i}`];
                      }

                    }              
                  } else {
                    validationErrors.value[`PIVA_ATTIVAZIONE_${i}`] = true
                  }
                  break;
                case 'DATA_DECORRENZA':
                case 'CITTA_FORNITURA':
                case 'SIGLA_PROVINCIA_FORNITURA':
                  if (el[`${element}`] != '' && el[`${element}`] != null) {
                    delete validationErrors.value[`${element}_${i}`];
                  } else {
                    validationErrors.value[`${element}_${i}`] = true
                  }
                  break;  
                case 'TOPONIMO_FORNITURA':
                case 'INDIRIZZO_FORNITURA':
                case 'CIVICO_FORNITURA':
                  el[`${element}`] = el[`${element}`].toString()
                  if (el[`${element}`].trim() == '') {
                    validationErrors.value[`${element}_${i}`] = true
                  } else {
                    delete validationErrors.value[`${element}_${i}`];
                  }
                  break;
                case 'CAP_FORNITURA':
                  el[`${element}`] = el[`${element}`].toString()
                  if (el[`${element}`].trim() == '' || el[`${element}`].length != 5) {
                    validationErrors.value[`${element}_${i}`] = true
                  } else {
                    delete validationErrors.value[`${element}_${i}`];
                  } 
                  break;
                case 'REMI':
                  if (el[`${element}`].trim() == '' || el[`${element}`].trim() == null) {
                    validationErrors.value[`${element}_${i}`] = true
                  } else {
                    delete validationErrors.value[`${element}_${i}`];
                    if (el[`${element}`].toString().length == 8 && el[`${element}`].toString() != '00000000') {
                      delete validationErrors.value[`${element}_ERROR_${i}`];
                    } else {
                      validationErrors.value[`${element}_ERROR_${i}`] = true
                    }
                  }
                  break;
                case 'CONSUMO_ANNUO':
                  if (el[element] <= 0) {
                    validationErrors.value[`${element}_${i}`] = true
                  } else {
                    delete validationErrors.value[`${element}_ERROR_${i}`];
                  }
                  break;
              }
            }
          })
        });
        type_device = Array.from(new Set(type_device));
        if (type_device.length > 1) {
          type_matrice.value = 'ee/gas'
        }else{
          type_matrice.value = type_device[0] == 'pod' ? 'ee' : 'gas'
        }
        let existentSupply = await checkSupply(supply)
        Object.keys(existentSupply.items).forEach((supply) => {
          Object.keys(existentSupply.items[supply]).forEach((pp) => {
            let exist = existentSupply.items[supply][pp]
            
            manualRows.value.forEach((el, i) => {
              if (el['POD_PDR'] == pp) {
                if (exist) {
                  validationErrors.value[`POD_PDR_exist_${i}`] = true
                  if (views.value == 'basic') {
                    let type_supply = type_device_supply[el.TIPO]
                    validationRowErrors.value[type_supply][`POD_PDR_EXISTENT`] = true
                  }
                }
              }
            })
          })
        })

        let activeSupply = await checkSupplyActive(supply)
        Object.keys(activeSupply.items).forEach((supply) => {
          Object.keys(activeSupply.items[supply]).forEach((pp) => {
            let exist = activeSupply.items[supply][pp]
            manualRows.value.forEach((el, i) => {
              if (el['POD_PDR'] == pp) {
                if (exist) {
                  toast.add({severity:'info', summary:'Fornitura già attiva', detail: "La fornitura "+el['POD_PDR']+" risulta già attiva.", life:3000});
                }
              }
            })
          })
        })
        
        if (Object.keys(validationErrors.value).length > 0) {
          toast.add({severity:'warn', summary:'Campi Errati', detail: 'Alcuni campi sembrano vuoti o errati', life:3000});
          if (views.value == 'basic') {
            setErrorsLiteRows()
          }
          return false
        }
        return true
      }
      const setErrorsLiteRows = () => {
        manualRows.value.forEach((row, i)=>{
          let type_supply = type_device_supply[row.TIPO]
          
          Object.keys(row).forEach(prop=>{
            if (`${prop}_${i}` in validationErrors.value) {
              
              validationRowErrors.value[type_supply][prop] = true
            }
          })
        })
        initManualRows()
        validationErrors.value = []
      }

      const verificaCodiceDistributore = (stringa) => {
        const indiceInizio = stringa.indexOf("IT") + 2; // Indice del primo numero
        const indiceFine = stringa.indexOf("E", indiceInizio);
        const treCifre = stringa.substring(indiceInizio, indiceFine);
        return cod_distributori.value.includes(treCifre);
      }

      const initValidationRowErrors = () => {
        validationRowErrors.value = {
          ee:{
            RAGIONE_SOCIALE: false,
            CODICE_FISCALE_PIVA: false,
            POD_PDR: false,
            POD_PDR_EXISTENT: false,
            POD_PDR_INVALID: false,
            TOPONIMO_FORNITURA: false,
            INDIRIZZO_FORNITURA: false,
            CIVICO_FORNITURA: false,
            CAP_FORNITURA: false,       
            SIGLA_PROVINCIA_FORNITURA: false,
            CITTA_FORNITURA: false,
            USO: false,
            OFFERTA: false,
          },
          gas:{
            RAGIONE_SOCIALE: false,
            CODICE_FISCALE_PIVA: false,
            POD_PDR: false,
            POD_PDR_INVALID: false,
            POD_PDR_EXISTENT: false,
            TOPONIMO_FORNITURA: false,
            INDIRIZZO_FORNITURA: false,
            CIVICO_FORNITURA: false,
            CAP_FORNITURA: false,       
            SIGLA_PROVINCIA_FORNITURA: false,
            CITTA_FORNITURA: false,
            USO: false,
            OFFERTA: false,
            MATRICOLA: false,
            CLASSE_MISURATORE: false,
            DESTINAZIONE_USO: false,
            CONSUMO_ANNUO:false
          }
        }
      } 


      const onChangeDate = (evt, i) => {
        delete validationErrors.value[`DATA_DECORRENZA_${i}`]
      }

      const checkSupply = async (obj) => {
        emit("activeLoader", true)
        return service.checkSupply(obj)
        .finally(() => {
          emit("activeLoader", false)
        })   
      }

      const checkSupplyActive = async (obj) => {
        emit("activeLoader", true)
        return service.checkSupplyActive(obj)
        .finally(() => {
          emit("activeLoader", false)
        })   
      }
      const isResidential = computed (()=>{
        // let residential = true
        // if (views.value == 'basic' && row_ee.value.exist) {
        //   residential = row_ee.value.uso && row_ee.value.uso == 'Domestico non residente'
        // }
        // return true
        return row_ee.value.uso && row_ee.value.uso == 'Domestico residente'
      })

      const openCodDistributori = () => {
        visibleCodDistributori.value = !visibleCodDistributori.value;
      }


      return { 
        activeTabIndex,
        downloadTemplate,
        headings,
        manualRows,
        addRow,
        deleteRow,
        editingRows,
        validationErrors,
        submitted, 
        prevPage, 
        validateForm, 
        handleFileUpload,
        fileToUpload,
        offerte,
        allowedType,
        keyInputReset,
        nextPage,
        importRowsFileToTable,
        deleteAllRows,
        onCellEditInit,
        onCellEditComplete,
        removeFile,
        showModal,
        matrice_id,
        cities,
        districts,
        onSelectedDistrict,
        onSelectedSdi,
        onSelectedCity,
        onSelectedClasse,
        onSelectedDestinazione,
        getDistricts,
        citiesGrouped,
        onSelectedOfferta,
        onSelectedUso,
        onSelectedRemi,
        usiOption,
        usiEnergia,
        usiGas,
        offerteOption,
        offerteEnergia,
        offerteGas,
        esiti_voltura,
        esiti_subentro,
        prechecked,
        remiOption,
        destinazioneOption,
        classeOption,
        sdi,
        toggle,
        op,
        fields,
        openPosition,
        visible,
        slides,
        typeClient,
        getOfferte,
        setOfferte,
        breakpoint,
        windowSize,
        responsiveLayout,
        // has_cf_attivazione,
        maskCF,
        // has_piva_attivazione,
        minDate,
        is_pratica,
        pratica,
        onInputCF_PIVA,
        onChangeDate,
        onInput,
        isVisibleAddress,
        views,
        cliente,
        addRowForType,
        row_ee,
        row_gas,
        removeRowForType,
        onSelectedDistrictForType,
        checked_basic_view,
        changeViews,
        canGoToNextPage,
        isManualContract,
        validationRowErrors,
        removeError,
        usiEnergiaDomestico,
        usiGasDomestico,
        isResidential,
        cod_distributori,
        visibleCodDistributori,
        openCodDistributori,
      }
    },
  }
</script>
<style>
.text_toupper{
  text-transform: uppercase;
}
.stepUpload-multisito .p-hidden-accessible{
  display: none;
}

.p-success {
  color: rgb(4, 254, 0);
  font-weight:400;
}

.font-size{
  font-size: 1rem;
}

@media screen and (max-width: 400px){
  .font-size{
    font-size: x-small;
  }
}
</style>

<style scoped>
  .btn-offerte{
    border-radius: 50% !important;
    background-color: rgb(233, 233, 233);
    color: rgb(105, 105, 105);
    border: 1px solid rgba(255, 255, 255, 0);
  }
  .btn-offerte:hover {
    background-color: rgb(233, 233, 233);
    color: rgb(105, 105, 105);
    border-color: #2196F3;
  }

.p-card{
  min-width: 335px !important;
}

.form-file .button-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 250px;
  border: 2px dashed rgba(156, 156, 156, 0.577);
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.form-file .allowed-format {
  font-size: 12px;
  color: rgb(186, 186, 186);
  margin-top: 2px;
}

.form-file .button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 250px;
  background: #ffffff;
  /* border: 1px solid rgb(217, 217, 217) !important;  */
  cursor: pointer;
  color: rgb(168, 168, 168);
  padding: 10px 2px;
  text-transform:uppercase;
  font-size:12px;
  border-radius: 3px;
  box-shadow: none;
}

.form-file  .remove-file {
    color: red;
    margin-top:15px;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
}

.form-file .button-wrapper span.label.active {
  color: #2196F3;
}

.form-file #upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100% !important;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.p-button-luce{
  background: linear-gradient(180deg, rgba(249,255,0,.65) 0%, rgba(189,255,0,.65) 100%);
  border-color: rgba(249,255,0,1);
  color:rgb(33, 150, 243);
}
.p-button-gas{
  background: linear-gradient(180deg, rgba(0,112,255,.65) 0%, rgba(0,189,255,.65) 100%);
  border-color: rgba(0,112,255,1);
  color:rgb(255, 255, 255);
}
.p-button-luce:hover{
  background: linear-gradient(180deg, rgba(249,255,0,1) 0%, rgba(189,255,0,1) 100%);
  border-color: rgba(249,255,0,1);
  color:rgb(33, 150, 243);
}
.p-button-gas:hover{
  background: linear-gradient(180deg, rgba(0,112,255,1) 0%, rgba(0,189,255,1) 100%);
  border-color: rgba(0,112,255,1);
  color:rgb(255, 255, 255);
}

.p-button-custom:hover{
  opacity: 1;
}
.p-button-custom{
  width: 100% !important;
  aspect-ratio: 1.8/1;
  border-radius: 3%;
}
.text-yellow{
  color: rgba(249,255,0,1);
}
.icon-custom{
  width: 21px;
  aspect-ratio: 1;
}
</style>

          
      