<template>
  <Loader v-if="loading" />
  <Card>
      <template v-slot:title>
        <h4>Gestisci pratica</h4>
      </template>
      <template v-slot:content>
        <TabView v-model:activeIndex="activeTabIndex" selectOnFocus>
          <TabPanel>
            <template #header>
              <div class="flex align-items-center gap-2">
                  <span class="font-bold white-space-nowrap mr-2">Precheck</span>
                  <Badge :value="countPrecheckPod" size="large" severity="warning"/>
                  <span class="font-bold white-space-nowrap m-1"> + </span>
                  <Badge :value="countPrecheckPdr" size="large"/>
              </div>
            </template>
            <div class="grid m-5">
              <div class="col-12 sm:col-6" v-if="countPrecheckPod">
                <div class="flex justify-content-center m-4">
                  <Button label="Scarica file Precheck energia" :disabled="downloadedPk" icon="pi pi-cloud-download" @click="downloadPrecheckFile('ee')" class="p-button-raised p-button-warning btn-download"/>
                </div> 
              </div>
              <div class="col-12 sm:col-6" v-if="countPrecheckPdr">
                <div class="flex justify-content-center m-4">
                  <Button label="Scarica file Precheck gas" :disabled="cuntPrecheckPdr == 0 || downloadedPk" icon="pi pi-cloud-download" @click="downloadPrecheckFile('gas')" class="p-button-raised btn-download"/>
                </div>
              </div>

              <div class="col-12" v-if="downloadedPk">
                <p class="mb-4 flex justify-content-center">File generato: Precheck {{ filename.startsWith('PKG') ? 'Gas' : 'Energia'}} <strong>{{ filename }}</strong></p>
                <div class="mb-4 flex justify-content-center">
                  <br>
                  <div>
                    <label for="id_pratica_pk">Inserisci l'Id della richiesta</label><br>
                    <InputMask id="id_pratica_pk" v-model="id_pratica_pk" mask="9999999"/>
                  </div>
                </div>
                <div class="flex justify-content-center">
                  <Button icon="pi pi-link" label="Salva l'ID della richiesta" @click="saveIdPratica('pk')" :disabled="id_pratica_pk == ''"/>
                </div>
              </div>
            </div>

            <div class="card" v-if="itemsPk && itemsPk.length > 0">
              <DataTable :value="itemsPk" :sortOrder="-1" sortField="data_creazione" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" breakpoint="1190px">
                <template #empty>
                  <div class="text-center">Nessun dato</div>
                </template>
                <Column field="filename" header="File di richiesta">
                  <template #body="{data}">
                    <Button :label="data.filename" v-if="!data.id_richiesta" style="font-size: 10px; max-width: fit-content;" v-tooltip="'Scarica file di richiesta'" @click="downloadPrecheckFile(data.filename.startsWith('PKG1') ? 'gas' : 'ee', data.filename)" icon="pi pi-download" severity="info"/>
                    <p v-else>
                      {{ data.filename}}
                    </p>
                  </template>
                </Column>
                <Column field="total" header="PdP">
                  <template #body="{data}">
                    <p v-tooltip="`${data.codes}`">{{ data.total }}</p>
                  </template>
                </Column>
                <Column field='data_creazione' header="Data Creazione" :sortable="true">
                  <template #body="{data}">
                    {{moment(data.data_creazione).format('DD-MM-YYYY HH:mm')}}
                  </template>
                </Column>
                <Column field="ID richiesta" header="ID richiesta">
                  <template #body="{data, index}">
                    <InputMask v-if="data.id_richiesta == null" v-model="id_pratica_pk_insert[index]" mask="9999999" style="max-width: 5rem;"/>
                    <p v-else> {{ data.id_richiesta }} </p>
                  </template>

                </Column>
                <Column field='processed' header="Completo">
                  <template #body="{data}">
                    <span v-if="data.processed">
                      <i class="pi pi-check text-green-400" style="font-size: 1rem"></i>
                    </span>
                    <span v-else>
                      <i class="pi pi-ban p-error" style="font-size: 1rem"></i>
                    </span>
                  </template>
                </Column>
                <Column field='data_elaborazione' header="Data Elaborazione" :sortable="true">
                  <template #body="{data}">
                    {{data.data_elaborazione ? moment(data.data_elaborazione).format('DD-MM-YYYY HH:mm') : '--'}}
                  </template>
                </Column>

                <Column :exportable="false">
                  <template #body="{data, index}">
                    <FileUpload style="font-size: 10px; max-width: fit-content;" v-if="!data.processed && data.id_richiesta" :key="fileUploadKeyPK" mode="basic" :auto="true" accept=".csv" name="filePKToUpload[]" customUpload @uploader="uploadResponsePratica($event, data.id_richiesta, 'pk', data.filename.startsWith('PKG1') ? 'Gas' : 'Energia')" chooseLabel="Carica Esito" />
                    <Button style="font-size: 10px; max-width: fit-content;" v-if="data.processed" label="Scarica esito" icon="pi pi-cloud-download" @click="downloadResponsePrecheckFile($event, data.id_richiesta, data.filename.startsWith('PKG1') ? 'Gas' : 'Energia')" severity="success"/>
                    <Button style="font-size: 10px; max-width: fit-content;" v-if="!data.id_richiesta && (id_pratica_pk_insert[index] != null && id_pratica_pk_insert[index] != '')" icon="pi pi-link" label="Salva l'ID della richiesta" :disabled="id_pratica_pk_insert[index] == ''" @click="saveIdPratica('pk', data.filename, id_pratica_pk_insert[index], index)"/>
                  </template>
                </Column>
              </DataTable>
            </div>
          </TabPanel>

          <TabPanel>
            <template #header>
              <div class="flex align-items-center gap-2">
                  <span class="font-bold white-space-nowrap mr-2">Informativa Voltura</span>
                  <Badge :value="countIvPod" size="large" severity="warning"/>
              </div>
            </template>
            <div class="grid justify-content-center m-5">
              <div class="flex justify-content-center m-4" v-if="countIvPod">
                <Button label="Scarica file Informativa Voltura" :disabled="countIvPod == 0" icon="pi pi-cloud-download" @click="downloadIvFile()" class="p-button-raised p-button-warning btn-download"/>
              </div>

              <div class="col-12" v-if="downloadedIv">
                <div class="mb-4 flex justify-content-center">
                  <div>
                    <label for="id_pratica_iv">Inserisci l'Id della richiesta</label><br>
                    <InputMask id="id_pratica_iv" v-model="id_pratica_iv" mask="9999999"/>
                  </div>
                </div>
                <div class="flex justify-content-center">
                  <Button icon="pi pi-link" label="Salva l'ID della richiesta" @click="saveIdPratica('iv')" :disabled="id_pratica_iv == ''"/>
                </div>
              </div>
            </div>
            <div class="card" v-if="itemsIv && itemsIv.length > 0">
              <DataTable :value="itemsIv" :sortOrder="-1" sortField="data_creazione" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]">
                <template #empty>
                  <div class="text-center">Nessun dato</div>
                </template>
                <Column field="filename" header="File di richiesta">
                  <template #body="{data}">
                    <Button :label="data.filename" v-if="!data.id_richiesta" style="font-size: 10px;" v-tooltip="'Scarica file di richiesta'" @click="downloadIvFile(data.filename)" icon="pi pi-download" severity="info"/>
                    <p v-else>
                      {{ data.filename}}
                    </p>
                  </template>
                </Column>
                <Column field="total" header="PdP">
                  <template #body="{data}">
                    <p v-tooltip="`${data.codes}`">{{ data.total }}</p>
                  </template>
                </Column>
                <Column field='data_creazione' header="Data Creazione" :sortable="true">
                  <template #body="{data}">
                    {{moment(data.data_creazione).format('DD-MM-YYYY HH:mm')}}
                  </template>
                </Column>

                <Column field="ID richiesta" header="ID richiesta">
                  <template #body="{data, index}">
                    <InputMask v-if="data.id_richiesta == null" v-model="id_pratica_iv_insert[index]" mask="9999999" style="max-width: 5rem;"/>
                    <p v-else> {{ data.id_richiesta }} </p>
                  </template>

                </Column>
                <Column  field='processed' header="Completo">
                  <template #body="{data}">
                    <span v-if="data.processed">
                      <i class="pi pi-check text-green-400" style="font-size: 1rem"></i>
                    </span>
                    <span v-else>
                      <i class="pi pi-ban p-error" style="font-size: 1rem"></i>
                    </span>
                  </template>
                </Column>
                <Column field='data_elaborazione' header="Data Elaborazione" :sortable="true">
                  <template #body="{data}">
                    {{data.data_elaborazione ? moment(data.data_elaborazione).format('DD-MM-YYYY HH:mm') : '--'}}
                  </template>
                </Column>

                <Column :exportable="false">
                  <template #body="{data, index}">
                    <FileUpload style="font-size: 10px; max-width: fit-content;" v-if="!data.processed && data.id_richiesta" :key="fileUploadKeyIV" mode="basic" :auto="true" accept=".csv" name="fileIVToUpload[]" customUpload @uploader="uploadResponsePratica($event, data.id_richiesta, 'iv')" chooseLabel="Carica Esito" />
                    <Button style="font-size: 10px; max-width: fit-content;" v-if="data.processed" label="Scarica esito" icon="pi pi-cloud-download" @click="downloadResponseIvFile($event, data.id_richiesta)" severity="success"/>
                    <Button style="font-size: 10px; max-width: fit-content;" v-if="!data.id_richiesta && (id_pratica_iv_insert[index] != null && id_pratica_iv_insert[index] != '')" icon="pi pi-link" label="Salva l'ID della richiesta" :disabled="id_pratica_iv_insert[index] == ''" @click="saveIdPratica('iv', data.filename, id_pratica_iv_insert[index], index)"/>
                  </template>
                </Column>
              </DataTable>
            </div>
          </TabPanel>
        </TabView>     

      </template>
    </Card>
  
</template>

<script>
import {ref, onMounted, getCurrentInstance } from 'vue';
import Loader from "@/components/Loader.vue"
import Service from '@/services/ask-pratiche/pratiche-wizard'
import { useRouter } from 'vue-router'
import moment from 'moment'
var CSV = require("xlsx");
import { useToast } from "primevue/usetoast";
   
export default {
  components: {
    Loader,
  },
  
  setup (props, {emit}) { 
    const toast = useToast();
    const loading = ref(false)
    const activeTabIndex = ref(0)
    const activeTabIndexMethod = ref(0)
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const countIvPod = ref(0)
    const countPrecheckPod = ref(0)
    const countPrecheckPdr = ref(0)
    const filename = ref('')
    const types = ref([
      {name:'ee', description: 'Energia'},
      {name:'gas', description: 'Gas'}
    ]);
    const router = useRouter()
    const allowedType = {
      'csv' : 'csv'
    }
    const ids = ref([])
    const keyInputReset = ref(0)
    const filePKToUpload = ref([])
    const fileIVToUpload = ref([])
    const headingsIV = ["COD_SERVIZIO", "COD_FLUSSO", "PIVA_UTENTE", "PIVA_GESTORE", "CP_UTENTE", "CP_GESTORE", "VERIFICA_AMM", "COD_CAUSALE", "MOTIVAZIONE", "COD_POD", "STATO_POD", "PMA_POD", "TENSIONE", "TOPONIMO", "VIA", "CIV", "CAP", "ISTAT", "LOCALITA", "PROV", "NAZIONE", "ALTRO", "MERCATO_PROVENIENZA", "POTCONTRIMP", "POTDISP", "CRPP_F1_AAM", "CRPP_F2_AAM", "CRPP_F3_AAM", "CRPP_F1_AAM1", "CRPP_F2_AAM1", "CRPP_F3_AAM1", "DATA_DECORRENZA_SW", "DATA_DECORRENZA_VT_TIT_III", "DATA_DECORRENZA_VT_TIT_IV"]
    const headingsPK = ["COD_SERVIZIO", "COD_FLUSSO", "PIVA_VENDITORE", "PIVA_GESTORE", "CP_VENDITORE", "CP_GESTORE", "VERIFICA_AMM", "COD_CAUSALE", "MOTIVAZIONE", "ESITO", "COD_ESITO", "DETTAGLIO_ESITO", "COD_POD", "CF", "PIVA", "CF_STRANIERO", "DATA_SALVAGUARDIA", "CRPP_MM_F1", "CRPP_MM_F2", "CRPP_MM_F3", "CRPP_MM1_F1", "CRPP_MM1_F2", "CRPP_MM1_F3", "RISOLUZIONE_CONTRATTUALE_TIMOE", "PIVA_CONTROPARTE_COMMERCIALE", "RAG_SOC_CONTR_COMM", "EMAIL_CONTR_COMM", "PMA_POD"]
    const headingsPKG = ["COD_SERVIZIO", "COD_FLUSSO", "PIVA_VENDITORE", "PIVA_GESTORE", "CP_VENDITORE", "CP_GESTORE", "VERIFICA_AMM", "COD_CAUSALE", "MOTIVAZIONE", "ESITO", "COD_ESITO", "DETTAGLIO_ESITO", "COD_PDR", "COD_REMI", "TIPO_PDR", "PIVA_DISTRIBUTORE", "CF", "PIVA", "CF_STRANIERO", "NOME", "COGNOME", "RAGIONE_SOCIALE_DENOMINAZIONE", "RAGIONE_SOCIALE_CONTROPARTECOMMERCIALE", "PIVA_CONTROPARTECOMMERCIALE", "POSTA_CERTIFICATA"]

    const downloadedIv = ref(false)
    const downloadedPk = ref(false)
    const id_pratica_iv = ref('')
    const id_pratica_iv_insert = ref([])

    const id_pratica_pk = ref('')
    const id_pratica_pk_insert = ref([])

    const itemsIv = ref()
    const itemsPk = ref()
    const ids_richiesta = ref([])


    const submitted = ref(false)
    const fileUploadKeyIV = ref(0);
    const fileUploadKeyPK = ref(0);


    onMounted(() => { 
      loading.value = true
      const promises = [
        getCountSupplyForPrecheck('ee'),
        getCountSupplyForPrecheck('gas'),
        getCountPodForIV(),
        getActivePratiche()
      ]
      Promise.all(promises).finally(()=>{
        loading.value = false
      })
    })

    const getActivePratiche = async () => {
      await service.getPratiche().then((res)=>{
        res.items.forEach(pratica => {
          ids_richiesta.value.push(pratica.id_richiesta)
        });
        itemsIv.value = res.items.filter(el => el.filename.startsWith('IV'))
        itemsPk.value = res.items.filter(el => el.filename.startsWith('PK'))
      })
    }
    const getCountPodForIV = async () => {
      await service.getCountPodForIV().then((res) => {
        countIvPod.value = res.items
      })
    }
    const saveIdPratica = (type_pratica, file = null, id = null, index = null) => {
      let id_pratica
      loading.value = true

      switch (type_pratica) {
        case 'iv':
          id_pratica = file ? id : id_pratica_iv.value
          break;
        case 'pk':
          id_pratica = file ? id : id_pratica_pk.value
          break;
      }

      if (ids_richiesta.value.includes(id_pratica)) {
        loading.value = false
        toast.add({severity:'warn', summary:'Associazione non riuscita', detail: `ID di Richiesta già utilizzato`, life: 10000});
        if (file) {
          switch (type_pratica) {
            case 'iv':
              delete id_pratica_iv_insert.value[index]
              break;
            case 'pk':
              delete id_pratica_pk_insert.value[index]
              break;
          }
        } else {
          switch (type_pratica) {
            case 'iv':
              id_pratica_iv.value = ''
              break;
            case 'pk':
              id_pratica_pk.value = ''
              break;
          }          
        }
        return
      }

      let severity = 'success'
      let summary = 'Associazione eseguita'
      let detail = `${file ?? filename.value} è stato associato all'id ${id_pratica}`

      service.saveIdPratica(file ?? filename.value, id_pratica)
      .then((res)=>{
        loading.value = false
        if (!res) {
          severity = 'warn'
          summary = 'Associazione non riuscita'
          detail = `${file ?? filename.value} non è stato associato all'id ${id_pratica}`
        }
        toast.add({severity:severity, summary:summary, detail: detail, life: 10000});
      })
      .finally(()=>{
        getActivePratiche()
        if (type_pratica == 'iv') {
          id_pratica_iv.value = ''
          downloadedIv.value = false
        } else {
          id_pratica_pk.value = ''
          downloadedPk.value = false         
        }
      })
    }
    const getCountSupplyForPrecheck = async (type) => {
      await service.getCountSupplyForPrecheck(type).then((res) => {
        if (type == 'ee') {
          countPrecheckPod.value = res.items          
        } else {
          countPrecheckPdr.value = res.items          
        }
      })
    }
    const goToCaricamenti = () => {
      router.push({path: '/caricamenti-multisito'})
    }
    const removeFile = (type) => {
      keyInputReset.value = new Date()
      if (type == 'pk') {
        filePKToUpload.value = []
      } else {
        fileIVToUpload.value = []
      }
    }
    const uploadResponsePratica = async (event, id, type, supply = null) => {
      submitted.value = true
      let filename = event.files[0].name.replace('risposte_','')
      let id_file = filename.replace('.csv','')
      if (id_file == id) {
        eval(`file${type.toUpperCase()}ToUpload`).value = [event.files[0]]
        if (await validateFile(type, supply)) {
          loading.value = true
          service.uploadPraticaFile(eval(`file${type.toUpperCase()}ToUpload`).value, id, type, supply)
          .then((res)=>{
            if (res) {
              toast.add({severity:'success', summary:'Importazione eseguita', detail: `Elaborazione del file di risposta eseguita correttamente`, life: 10000});
            } else {
              toast.add({severity:'warn', summary:'Importazione fallita', detail: `Elaborazione del file di risposta non riuscita`, life: 10000});
            }
            eval(`file${type.toUpperCase()}ToUpload`).value = []
          })
          .finally(()=> {
            getActivePratiche()
            loading.value = false
          })
        }
      }else{
        toast.add({severity:'warn', summary:'Importazione fallita', detail: `Il file di risposta inserito non corrisponde alla richiesta selezionata`, life: 10000});
        eval(`fileUploadKey${type.toUpperCase()}`).value++;
      }
    }
    // const uploadResponseIV = async (event,id) => {
    //   submitted.value = true
    //   let filename = event.files[0].name.replace('risposte_','')
    //   let id_file = filename.replace('.csv','')
    //   if (id_file == id) {
    //     fileIVToUpload.value = [event.files[0]]
    //     if (await validateFile('iv')) {
    //       loading.value = true
    //       service.uploadIvFile(fileIVToUpload.value, id)
    //       .then((res)=>{
    //         if (res) {
    //           toast.add({severity:'success', summary:'Importazione eseguita', detail: `Elaborazione del file di risposta eseguita correttamente`, life: 10000});
    //         } else {
    //           toast.add({severity:'warn', summary:'Importazione fallita', detail: `Elaborazione del file di risposta non riuscita`, life: 10000});
    //         }
    //         fileIVToUpload.value = []
    //       })
    //       .finally(()=> {
    //         getActivePratiche()
    //         loading.value = false
    //       })
    //     }
    //   }else{
    //     toast.add({severity:'warn', summary:'Importazione fallita', detail: `Il file di risposta inserito non corrisponde alla richiesta selezionata`, life: 10000});
    //     fileUploadKeyIV.value++;
    //   }
    // }

    // const uploadResponsePrecheck = async (event, id, supply) => {
    //   submitted.value = true
    //   let filename = event.files[0].name.replace('risposte_','')
    //   let id_file = filename.replace('.csv','')
    //   if (id_file == id) {
    //     filePKToUpload.value = [event.files[0]]
    //     if (await validateFile('pk', supply)) {
    //       loading.value = true
    //       service.uploadPrecheckFile(filePKToUpload.value, id, supply)
    //       .then((res)=>{
    //         if (res) {
    //           toast.add({severity:'success', summary:'Importazione eseguita', detail: `Elaborazione del file di risposta eseguita correttamente`, life: 10000});
    //         } else {
    //           toast.add({severity:'warn', summary:'Importazione fallita', detail: `Elaborazione del file di risposta non riuscita`, life: 10000});
    //         }
    //         filePKToUpload.value = []
    //       })
    //       .finally(()=> {
    //         getActivePratiche()
    //         loading.value = false
    //       })
    //     }
    //   }else{
    //     toast.add({severity:'warn', summary:'Importazione fallita', detail: `Il file di risposta inserito non corrisponde alla richiesta selezionata`, life: 10000});
    //     fileUploadKeyPK.value++;
    //   }
    // }

    const downloadIvFile = async (file = null) => {
      loading.value = true
      if (file) {
        await service.downloadFileName(file)
        .finally(()=> {
          getCountPodForIV()
          loading.value = false
        })
      } else {
        filename.value = await service.downloadIvFile()
        .finally(()=> {
          getCountPodForIV()
          loading.value = false
          downloadedIv.value = true
        })        
      }
    }
    const downloadResponseIvFile = async (event,id) => {
      loading.value = true
      filename.value = await service.downloadResponseIvFile(id)
      .finally(()=> {
        loading.value = false
      })
    }
    const downloadResponsePrecheckFile = async (event,id, supply) => {
      loading.value = true
      filename.value = await service.downloadResponsePrecheckFile(id, supply)
      .finally(()=> {
        loading.value = false
      })
    }

    const downloadPrecheckFile = async (type, file = null) => {
      loading.value = true
      if (file) {
        await service.downloadFileName(file)
        .finally(()=> {
          getCountSupplyForPrecheck(type)
          loading.value = false
        })
      }else{
        filename.value = await service.downloadPrecheckFile(type)
        .finally(()=> {
          getCountSupplyForPrecheck(type)
          loading.value = false
          downloadedPk.value = true
        })        
      }
    }
    const handleFileUpload = (evt, type) => {
      filePKToUpload.value = []
      fileIVToUpload.value = []
      evt.preventDefault()
      submitted.value = false;
      if (type == 'pk') {
        filePKToUpload.value = self.refs.filePK.files
      } else {
        fileIVToUpload.value = self.refs.fileIV.files
      }
    }
    const validateFile = async (check, supply = null)  => {
      if (check == 'iv') {
        if (fileIVToUpload.value.length > 0) {
          const data = await fileIVToUpload.value[0].arrayBuffer();
          const workbook = CSV.read(data);
          let first_sheet_name = workbook.SheetNames[0];
          let ws = workbook.Sheets[first_sheet_name];
          const headers = (CSV.utils.sheet_to_json(ws, { header: 1 }))[0];
          const isSameHeaders = JSON.stringify(headers) === JSON.stringify(headingsIV);
          if (!isSameHeaders) {
            toast.add({severity:'warn', summary:'Importazione fallita', detail: `Il file di risposta inserito non è conforme con la richiesta selezionata`});
            fileUploadKeyIV.value++;

            return false
          }else{
            return true
          }
        }
      }else{
        if (filePKToUpload.value.length > 0) {
          const data = await filePKToUpload.value[0].arrayBuffer();
          const workbook = CSV.read(data);
          let first_sheet_name = workbook.SheetNames[0];
          let ws = workbook.Sheets[first_sheet_name];
          const headers = (CSV.utils.sheet_to_json(ws, { header: 1 }))[0];
          const isSameHeaders = JSON.stringify(headers) === JSON.stringify(supply == 'Gas' ? headingsPKG : headingsPK);
          if (!isSameHeaders) {
            toast.add({severity:'warn', summary:'Importazione fallita', detail: `Il file di risposta inserito non è conforme con la richiesta selezionata`});
            fileUploadKeyPK.value++;

            return false
          }else{
            return true
          }
        }

      }
    }

    return {
      loading,
      activeTabIndex,
      activeTabIndexMethod,
      downloadIvFile,
      countIvPod,
      allowedType,
      handleFileUpload,
      filePKToUpload,
      fileIVToUpload,
      keyInputReset,
      removeFile,
      uploadResponsePratica,
      // uploadResponseIV,
      // uploadResponsePrecheck,
      downloadPrecheckFile,
      countPrecheckPod,
      countPrecheckPdr,
      types,
      filename,
      downloadedIv,
      downloadedPk,
      id_pratica_iv,
      id_pratica_iv_insert,
      id_pratica_pk,
      id_pratica_pk_insert,
      saveIdPratica,
      getActivePratiche,
      itemsIv,
      itemsPk,
      fileUploadKeyIV,
      fileUploadKeyPK,
      downloadResponseIvFile,
      downloadResponsePrecheckFile,
      moment,
    }
  },
}
</script>

<style scoped>
.p-card{
  min-width: 335px !important;
}
.btn-red, .btn-red:hover{
  color: #ffffff;
  background: #d32f2f;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 3px;
  border: 1px solid #2196F3;
}
.form-file .button-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 250px;
  border: 2px dashed rgba(156, 156, 156, 0.577);
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.form-file .allowed-format {
  font-size: 12px;
  color: rgb(186, 186, 186);
  margin-top: 2px;
}

.form-file .button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 250px;
  background: #ffffff;
  cursor: pointer;
  color: rgb(168, 168, 168);
  padding: 10px 2px;
  text-transform:uppercase;
  font-size:12px;
  border-radius: 3px;
  box-shadow: none;
}

.form-file .remove-file {
    color: red;
    margin-top:15px;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
}

.form-file .button-wrapper span.label.active {
  color: #2196F3;
}

#uploadIV, #uploadPK {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
.btn-download{
  width: 173px;
}
</style>
